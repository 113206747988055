<template>
    <div class="alert alert-info" role="alert" style="margin-top: 1rem;">
        {{message}}
    </div>
</template>
<script>
// <div class="alert alert-info" role="alert" style="margin-top: 4.4rem;"> pro fixed top
export default {
    name: 'Message',
    props: ['message']
}
</script>