export default {
    translation: {
        login: 'Přihlášení',
        logout: 'Odhlášení',
        signup: 'Registrace',
        dashboard: 'Přehled',
        settings: 'Nastavení',
        users: 'Uživatelé',
        email: 'Email',
        password: 'Heslo',
        confirm_password: 'Potvrdit heslo',
        reset_password: 'Obnova hesla',
        forgot_password: 'Nepamatuji si heslo',
        reset_token_expired: 'Reset token není platný nebo vypršel čas pro jeho použití',
        invalid_username_password: 'Chybné jméno nebo heslo!',
        email_sent: 'Email byl odeslán!',
        email_reqiured: 'Položka Email je povinná',
        email_bad_format: 'Email má špatný formát',
        email_not_exist: 'Zadaný email neexistuje',
        user_email: 'Email uživatele',
        user_added: 'Uživatel byl úspěšně přidán',
        user_updated: 'Uživatel byl úspěšně změněn',
        user_settings: 'Nastavení uživatelů',
        add_user: 'Přidat uživatele',
        edit_user: 'Úpravy uživatele',
        user_deleted: 'Uživatel byl odstraněn',
        user_created: 'Uživatel byl úspěšně vytvořen',
        user_remove_q: 'Odstranit uživatele id: {{id}}?',
        user_logged_out: 'Uživatel byl odhlášen',
        user_logged_out_inactivity: 'Odhlášeno z důvodu nečinnosti',
        role: 'Role',
        confirmed: 'Ověřen',
        yes: 'Ano',
        no: 'Ne',
        num_of_devices: 'Počet zařízení',
        submit: 'Odeslat',
        email_verification: {
            heading: 'Ověření emailu', 
            text1: 'Děkujeme za registraci.',
            text2: 'Nyní se můžete přihlásit.',
            //text2: 'Pro dokončení ověřovacího procesu stiskněte tlačítko Ověřit emailovou adresu.',
            text3: 'Ověřuji email, vyčkejte na dokončení...',
            verify_button_text: 'Pokračovat',
            //verify_button_text: 'Ověřit emailovou adresu',
            token_expired: 'Ověřovací token již není platný nebo vypršel čas pro jeho použití.'
        },
        register_after: {
            heading: 'Registrace dokončena', 
            text1: 'Děkujeme za registraci.',
            text2: 'Odeslali jsme Vám ověřovací e-mail. Potvrďte jej a poté se můžete přihlásit.',
        },
        edit: 'Upravit',
        delete: 'Smazat',
        device: 'Zařízení',
        devices: 'Seznam zařízení',
        device_settings: 'Seznam zařízení',
        select_device: 'Vyberte zařízení',
        select_device_type: 'Vyberte typ zařízení',
        add_device: 'Přidat zařízení',
        edit_device: 'Úpravy zařízení',
        device_deleted: 'Zařízení bylo odstraněno',
        device_created: 'Zařízení bylo vytvořeno',
        device_added: 'Zařízení bylo úspěšně přidáno',
        device_name: 'Název',
        device_name_required: 'Název nesmí být prázdný',
        device_type: 'Typ zařízení',
        device_delete_confirm: 'Skutečně chcete smazat toto zařízení?',
        no_devices_in_list: 'Seznam zařízení je prázdný. Přidejte nové zařízení tlačítkem ',
        no_devices_in_dashboard: 'Nemáte na seznamu žádné zařízení. Nové zařízení můžete přidat ',
        here: 'zde',
        sim_list: 'Seznam SIM',
        enter_sim_number: 'Zadejte číslo SIM (19 číslic)',
        sim_number_required: 'Číslo SIM musí obsahovat přesně 19 číslic',
        sim_number_bad_format: 'Číslo SIM není správně zadané',
        sim_number_help: 'Zadejte číslo SIM, nachází se na SIM kartě pod názvem IccID (viz obrázek)',
        sim_number: 'Číslo SIM',
        add_sim: 'Přidat SIM',
        sim_added: 'Číslo SIM bylo úspěšně přidáno ',
        sim_deleted: 'Číslo SIM bylo odstraněno',
        sim_delete_confirm: 'Skutečně chcete smazat tuto SIM?',
        add_sim_failed: 'Přidání čísla SIM se nezdařilo',
        save_sim_failed: 'Uložení čísel SIM se nezdařilo',
        power_from_solar: 'Spotřeba ze sol.panelů',
        power_from_grid: 'Spotřeba ze sítě',
        power: 'Výkon',
        energy: 'Energie',
        temperature: 'Teplota',
        average_temperature: 'Průměrná teplota',
        time: 'Čas',
        grid: 'Síť',
        solar: 'Solar',
        solar_total: 'Solar celkem',
        no_data_available: 'Data nejsou dostupná',
        no_data_available_interval: 'Pro zadaný interval nejsou dostupná žádná data',
        no_sim_available: 'Zařízení nemá přiřazenou sim kartu.',
        overview: 'Přehled',
        tank: 'Bojler',
        tank_temperature: 'Bojler - teplota',
        tank_1_temperature: 'Bojler 1 - teplota',
        tank_2_temperature: 'Bojler 2 - teplota',
        tank_1_temperature_limit_ac: 'Bojler 1 - limit (Síť)',
        tank_1_temperature_limit_solar: 'Bojler 1 - limit (Solar)',
        tank_2_temperature_limit_solar: 'Bojler 2 - limit (Solar)',
        tank_temperature_limit_solar: 'Bojler - limit',
        error_occured: 'Nastala chyba!',
        error_load_meas_data: 'Nepodařilo se načíst naměřená data',
        error_load_actual_data: 'Nepodařilo se načíst aktuální data',
        filter: {
          from: 'Od:',
          to: 'Do:',
          select_interval: 'Vyberte interval',
          today: 'Dnes',
          yesterday: 'Včera',
          last_7_days: 'Posledních 7 dnů',
          last_month: 'Tento měsíc',
          custom: 'Vlastní'
        },
        no_date_selected: 'Vyberte datum',
        select_timezone: 'Vyberte časovou zónu',
        timezone: 'Časová zóna',
        profile_updated: 'Profil upraven',
        profile: 'Profil uživatele',
        save: 'Uložit',
        register_finished: 'Registrace dokončena',
        user_not_verified: 'Účet není aktivní - potvrďte ověřovací e-mail',
        user_already_exists: 'Uživatel již existuje',
        password_not_same: 'Hesla se neshodují',
        password_changed: 'Heslo bylo změněno',
        password_bad_format: 'Heslo musí obsahovat nejméně 8 znaků, z toho alespoň jedno velké, jedno malé písmeno a jednu číslici. Povolené znaky jsou písmena (bez diakritiky) a číslice.',
        close: 'Zavřít',
        confirm: 'Potvrdit',
        kerberos_error: {
            sensor_error: 'Chyba čidel',
            load_error: 'Nepřipojená zátěž',
            insulation_error: 'Chyba izolace tělesa',
            overheat_error: 'Přehřátí elektroniky',
            thermal_fuse_error: 'Teplotní pojistka',
            resistance_error: 'Chybný odpor tělesa',
            fan_error: 'Chyba ventilátoru',
            other_error: 'Jiný chybový stav'
        },
        kerberos_errors: "Chyby zařízení",
        kerberos_warnings: "Upozornění",
        sim_expiring: "Platnost SIM karty vyprší dne ",
        sim_expired: "Platnost SIM karty vypršela dne ",
        all: "Vše",
        current_state: "Aktuální stav",
        left: "Vlevo",
        right: "Vpravo",
        chart_tooltip: "Povolit/zakázat nápovědu ke grafu",
    }
}