<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="card-inner" >
      <div class="d-block;">
        <b-row>

          <b-col>
            <slot name="titleSlot">
              <h5 class="card-title text-uppercase text-muted mb-0" v-if="title">{{title}}</h5>
              <span class="h2 font-weight-bold mb-0" :class="[subTitleClasses]"
                v-if="subTitle">{{subTitle}}</span>
            </slot>
          </b-col>

          <b-col cols="auto" v-if="$slots.icon || icon" style="padding-left:0px;">
            <slot name="icon">
              <div class="icon icon-shape black rounded-circle shadow"
                  :class="[`bg-${type}`, iconClasses]">
                <b-icon :icon="icon" :variant="iconVariant" :color="iconColor"></b-icon>
              </div>
            </slot>
          </b-col>
        </b-row>
      </div>
      <div class="mt-0 mb-0 text-sm">
        <slot name="extra">

        </slot>
      </div>
      <div class="mt-3 mb-0 text-sm d-block">
        <slot name="footer">

        </slot>
      </div>
    </div>
  </card>
</template>
<script>
  import Card from './Card.vue';

  export default {
    name: 'stats-card',
    components: {
      Card
    },
    props: {
      type: {
        type: String,
        default: 'primary'
      },
      icon: String,
      title: String,
      subTitle: String,
      subTitleClasses: {
        type: [String, Array],
        default: ''
      },
      iconVariant: String,
      iconColor: String,
      iconClasses: [String, Array]
    }
  };
</script>
<style></style>
