import Vue from 'vue'
import Vuex from 'vuex'
import User from "./modules/user";
import Devices from "./modules/devices";
import UI from "./modules/ui";

Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
      user: User,
      devices: Devices,
      ui: UI
    }
  });