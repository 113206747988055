export default {
    translation: {
        login: 'Login',
        logout: 'Logout',
        signup: 'Register',
        dashboard: 'Dashboard',
        settings: 'Settings',
        users: 'Users',
        email: 'Email',
        password: 'Password',
        confirm_password: 'Confirm Password',
        reset_password: 'Reset password',
        forgot_password: 'Forgot password?',
        reset_token_expired: 'Reset token is not valid or expired',
        invalid_username_password: 'Invalid username/password!',
        email_sent: 'The email was sent!',
        email_reqiured: 'Email is required',
        email_bad_format: 'Email has wrong format',
        email_not_exist: 'Email does not exist',
        user_email: 'User Email',
        user_added: 'User added successfully',
        user_updated: 'User updated successfully',
        user_settings: 'User settings',
        add_user: 'Add user',
        edit_user: 'Edit user',
        user_deleted: 'User was deleted',
        user_created: 'User was created successfully',
        user_remove_q: 'Remove user id: {{id}}?',
        user_logged_out: 'User has been logged out',
        user_logged_out_inactivity: 'Logged out due to inactivity',
        role: 'Role',
        confirmed: 'Confirmed',
        yes: 'Yes',
        no: 'No',
        num_of_devices: 'Num of Devices',
        submit: 'Submit',
        email_verification: {
          heading: 'Email verification', 
          text1: 'Thank you for the registration.',
          text2: 'Now you can log in.',
          //text2: 'To complete the verification process, press the Verify Email Address button.',
          text3: 'Verifying email, please wait for completion...',
          //verify_button_text: 'Verify Email Address',
          verify_button_text: 'Continue',
          token_expired: 'Verification token is not valid or has expired.'
        },
        register_after: {
          heading: 'Registration finished', 
          text1: 'Thank you for the registration.',
          text2: 'We have sent you a verification email. Confirm it and then you can login.',
      },
        edit: 'Edit',
        delete: 'Delete',
        device: 'Device',
        devices: 'Devices',
        device_settings: 'Device settings',
        select_device: 'Select device',
        select_device_type: 'Please select device type',
        add_device: 'Add Device',
        edit_device: 'Edit Device',
        device_deleted: 'Device was deleted',
        device_created: 'Device was created successfully',
        device_added: 'Device was added successfully',
        device_name: 'Name',
        device_name_required: 'Name is required',
        device_type: 'Device Type',
        device_delete_confirm: 'Are you sure to delete this device?',
        no_devices_in_list: 'No devices in list. Add a new device with the button ',
        no_devices_in_dashboard: 'No devices in list. You can add new device ',
        here: 'here',
        sim_list: 'SIM list',
        enter_sim_number: 'Enter SIM number (19 digits)',
        sim_number_required: 'SIM number must contain exactly 19 digits',
        sim_number_bad_format: 'SIM number is not correct',
        sim_number_help: 'Enter the SIM number, it is located on the SIM card under the name IccID (see picture)',
        sim_number: 'SIM number',
        add_sim: 'Add SIM',
        sim_added: 'SIM added successfully',
        sim_deleted: 'SIM was deleted',
        sim_delete_confirm: 'Are you sure to delete this SIM number?',
        add_sim_failed: 'Failed to add SIM to device',
        save_sim_failed: 'Failed to save SIM to device',
        power_from_solar: 'Consumption from Solar',
        power_from_grid: 'Consumption from Grid',
        power: 'Power',
        energy: 'Energy',
        temperature: 'Temperature',
        average_temperature: 'Average temperature',
        time: 'Time',
        grid: 'Grid',
        solar: 'Solar',
        solar_total: 'Solar Total',
        no_data_available: 'No data available',
        no_data_available_interval: 'No data available for selected interval',
        no_sim_available: 'No sims associated with selected device',
        overview: 'Overview',
        tank: 'Tank',
        tank_temperature: 'Tank temperature',
        tank_1_temperature: 'Tank 1 temperature',
        tank_2_temperature: 'Tank 2 temperature',
        tank_1_temperature_limit_ac: 'Tank 1 limit (Grid)',
        tank_1_temperature_limit_solar: 'Tank 1 limit (Solar)',
        tank_2_temperature_limit_solar: 'Tank 2 limit (Solar)',
        tank_temperature_limit_solar: 'Tank temp.limit',
        error_occured: 'Error occured!',
        error_load_meas_data: 'Failed to load measured data',
        error_load_actual_data: 'Failed to load actual device data',
        filter: {
          from: 'From:',
          to: 'To:',
          select_interval: 'Select Interval',
          today: 'Today',
          yesterday: 'Yesterday',
          last_7_days: 'Last 7 days',
          last_month: 'This month',
          custom: 'Custom'
        },
        no_date_selected: 'No date selected',
        select_timezone: 'Select time zone',
        timezone: 'Time zone',
        profile_updated: 'Profile updated',
        profile: 'User Profile',
        save: 'Save',
        user_not_verified: 'User not verified, please confirm e-mail first',
        user_already_exists: 'User already exists',
        password_not_same: 'Passwords do not match',
        password_changed: 'Password has been changed',
        password_bad_format: 'The password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter and one number. Allowed characters are letters (without diacritics) and numbers.',
        close: 'Close',
        confirm: 'Confirm',
        kerberos_error: {
          sensor_error: 'Temperature sensor malfunction',
          load_error: 'Heating element malfunction',
          insulation_error: 'Heating element insulation malfunction',
          overheat_error: 'Overheat alarm',
          thermal_fuse_error: 'Thermal fuse alarm',
          resistance_error: 'Bad resistance',
          fan_error: 'Fan malfunction',
          other_error: 'Unknown error',
        },
        kerberos_errors: "Device errors",
        kerberos_warnings: "Notice",
        sim_expiring: "The SIM card is about to expire on ",
        sim_expired: "The SIM card expired on ",
        all: "All",
        current_state: "Current state",
        left: "Left",
        right: "Right",
        chart_tooltip: "Enable/disable chart tooltip",


    }
}