<template>
    <form @submit.prevent="handleSubmit">
        <Error v-if="error" :error="error"/>
        <h3>{{ $t("login") }}</h3>
        <div class="form-group">
            <label>{{ $t("email") }}</label>
            <input type="email" class="form-control" v-model="email" :placeholder="$t('email')"/>
        </div>
        <div class="form-group">
            <label>{{ $t("password") }}</label>
            <input type="password" class="form-control" v-model="password" :placeholder="$t('password')"/>
        </div>
        <button class="btn btn-primary btn-block">{{ $t("login") }}</button>
        <div class="d-flex justify-content-between">
            <div class="d-inline-flex"> 
                <p class="forgot-password text-right">
                    <router-link to="register">{{ $t("signup") }}</router-link>
                </p>
            </div>
            <div class="d-inline-flex">
                <p class="forgot-password">
                    <router-link to="forgot">{{ $t("forgot_password") }}</router-link>
                </p>
            </div>
        </div>
    </form>
</template>
<script>
//import axios from 'axios'
        //<Message v-if="message" :message="message"/>
import Error from './Error'
//import Message from './Message'
//import {mapGetters} from 'vuex'

export default {
    name: 'Login',
    components: {
        Error,
        //Message
    },
    data() {
        return {
            email: '',
            password: '',
            error: ''
        }
    },
    methods: {
        handleSubmit() {
            this.$store.dispatch("login", {
                email: this.email,
                password: this.password
            })
            .then(success => {
                console.log(success)
                this.$router.push("/")
            })
            .catch(error => {
                console.log(error)
                if (error && error.response && error.response.data == 'Uživatel ještě není potvrzený'){
                    this.error = this.$t("user_not_verified")
                }
                else if (error && !error.response && error.message){
                    this.error = error.message//this.$t("user_not_verified")
                }
                else   
                    this.error = this.$t("invalid_username_password")
            })
            this.$store.dispatch("set_message", "");
        }
        /*async handleSubmit() {
            try {
                
                //localStorage.setItem('token', null)
                //console.log(localStorage.getItem('token'))
                const response = await axios.post('login', {
                    email: this.email,
                    password: this.password
                })
                console.log(axios)
                console.log(response);
                localStorage.setItem('token', response.data.token)
                console.log(localStorage.getItem('token'))
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
                this.$store.dispatch('user', response.data.userDetails)
                this.$router.push('/')
            } catch (e) {
                this.error = 'Invalid username/password!'
            }
        }*/
        
    },
    //computed: {
    //    ...mapGetters(['message']),
    //},
}
</script>