<template>
    <div>
        <b-row v-if="showActualStandard">
            <b-col xl="3" md="6">
                <stats-card :title="$t('solar')"
                        :sub-title="actualSolar"
                        :subTitleClasses="actualSubtitleClass"
                        :type="cardProps.solar.type"
                        :icon="cardProps.solar.icon"
                        :iconColor="cardProps.solar.iconColor" 
                        class="mb-2 mb-md-4"
                        v-tooltip="actualInterval"
                        >
                    <template slot="footer">
                        <div class="d-flex align-baseline justify-content-between">
                            <div class="d-inline-flex">{{actualIntervalDate}}</div>
                            <div class="d-inline-flex"> {{actualIntervalTime}}</div>
                        </div>
                    </template>
                </stats-card>
            </b-col>
            <b-col xl="3" md="6">
                <stats-card :title="$t('grid')"
                        :sub-title="actualNetwork"
                        :subTitleClasses="actualSubtitleClass"
                        :type="cardProps.grid.type"
                        :icon="cardProps.grid.icon"
                        :iconColor="cardProps.grid.iconColor" 
                        class="mb-2 mb-md-4">

                    <template slot="footer">
                        <div class="d-flex align-baseline justify-content-between">
                            <div class="d-inline-flex">{{actualIntervalDate}}</div>
                            <div class="d-inline-flex"> {{actualIntervalTime}}</div>
                        </div>
                    </template>
                </stats-card>
            </b-col>
            <b-col xl="3" md="6">
                <stats-card :title="$t('temperature')"
                        :sub-title="actualTempTank1"
                        :subTitleClasses="actualSubtitleClass"
                        :type="cardProps.temp1.type"
                        :icon="cardProps.temp1.icon"
                        :iconColor="cardProps.temp1.iconColor" 
                        class="mb-2 mb-md-4">

                    <template slot="footer">
                        <span class="text-nowrap">{{$t('tank')}} 1</span>
                    </template>
                </stats-card>

            </b-col>
            <b-col xl="3" md="6">
                <stats-card :title="$t('temperature')"
                        :sub-title="actualTempTank2"
                        :subTitleClasses="actualSubtitleClass"
                        :type="cardProps.temp2.type"
                        :icon="cardProps.temp2.icon"
                        :iconColor="cardProps.temp2.iconColor" 
                        class="mb-2 mb-md-4">

                    <template slot="footer">
                        <span class="text-nowrap">{{$t('tank')}} 2</span>
                    </template>
                </stats-card>
            </b-col>
        </b-row>

        <b-card-group deck v-if="showActualPower">
            <stats-card 
                    :type="cardProps.solar.type"
                    :icon="cardProps.solar.icon"
                    :iconColor="cardProps.solar.iconColor" 
                    class="mb-2 mb-md-4"
                    v-tooltip="actualInterval"
                    >
                <template slot="titleSlot">
                    <h5 class="card-title text-uppercase text-muted mb-0" >{{$t('solar')}}</h5>
                    <span class="h2 font-weight-bold mb-0" :class="actualSubtitleClass"> {{totalSolar}}</span>
                    <div class="subtitle-description d-inline" > ({{actualDetailsPower}})</div>
                </template>
                <!--<template slot="extra">
                    <span class="">{{actualDetailsPower}}</span>
                </template>-->
                <template slot="footer">
                    <div class="d-flex align-baseline justify-content-between">
                        <div class="d-inline-flex">{{actualIntervalDate}}</div>
                        <div class="d-inline-flex"> {{actualIntervalTime}}</div>
                    </div>
                </template>
            </stats-card>
        
            <stats-card :title="$t('temperature')"
                    :sub-title="temp"
                    :subTitleClasses="actualSubtitleClass"
                    :type="cardProps.temp1.type"
                    :icon="cardProps.temp1.icon"
                    :iconColor="cardProps.temp1.iconColor" 
                    class="mb-2 mb-md-4">

                <template slot="footer">
                <div class="text-nowrap d-inline-block align-baseline">{{$t('tank')}}</div>
                </template>
            </stats-card>
        </b-card-group>

        <b-row v-if="!showActualData" class="text-center">
            <b-col><div class="mt-2">
                {{ deviceHasSim?($t('no_data_available') + (showActualError?(' (' + showActualError + ')'):'')):$t('no_sim_available') }}
            </div></b-col>
        </b-row>
    </div>
</template>

<script>
/*
<stats-card :title="$t('solar')"
                        :sub-title="totalSolar"
                        :subTitleClasses="actualSubtitleClass"
                        :type="cardProps.solar.type"
                        :icon="cardProps.solar.icon"
                        :iconColor="cardProps.solar.iconColor" 
                        class="mb-2 mb-md-4"
                        v-tooltip="actualInterval"
                        >
*/
//iconVariant="warning"
import {mapGetters} from 'vuex'

//import TankTempInfo from './TankTempInfo.vue'
//import ConsumptionTable from './ConsumptionTable.vue'

//import MixChart from '../Common/MixChart.js'
import StatsCard from '../Common/StatsCard';
//import FilterSelect from './FilterSelect.vue'
import { format, parseISO, differenceInMinutes } from 'date-fns'
//import { enGB, cs, fr } from 'date-fns/locale'
import consts from '../../consts.js'
//const locales = { en:enGB, cs, fr }

import 'chartjs-adapter-date-fns';

export default {
    name: 'ActualInfo',
    components: {
        //TankTempInfo,
        //MixChart,
        //FilterSelect,
        StatsCard,
        //ConsumptionTable
    },
    data() {
        return {
            isInversed:false
        }
    },
    props: [],
    computed: {
        ...mapGetters(['user', 'lang']),
        ...mapGetters(['actualDeviceData','deviceData', 'filterType', 'filterFrom', 'filterTo', 'selectedDeviceId', 'selectedDevice']),
        cardProps(){

            if (!this.isInversed) return {
                solar: {
                    type:"white",
                    icon:"sun",
                    iconColor:"#f87979" 
                },
                grid: {
                    type:"white",
                    icon:"lightning",
                    iconColor:"#17a2b8"
                },
                temp1: {
                    type:"white",
                    icon:"thermometer-half",
                    iconColor:"blue"
                },
                temp2: {
                    type:"white",
                    icon:"thermometer-half",
                    iconColor:"green"
                }
            }
            else return {
                solar: {
                    type:"solar",
                    icon:"sun",
                    iconColor:"white" 
                },
                grid: {
                    type:"grid",
                    icon:"lightning",
                    iconColor:"white"
                },
                temp1: {
                    type:"temp1",
                    icon:"thermometer-half",
                    iconColor:"white"
                },
                temp2: {
                    type:"temp2",
                    icon:"thermometer-half",
                    iconColor:"white"
                }
            }
        },
        deviceHasSim(){
            var result = (this.selectedDevice && this.selectedDevice.sims && (this.selectedDevice.sims.length > 0))
            return result
        },
        // ******* STANDARD *******
        actualSolar(){
            var {actualSolar = 0} = this.actualDeviceData || {}
            return this.calcPower(actualSolar)
        },
        actualNetwork(){
            var {actualGrid = 0} = this.actualDeviceData || {}
            return this.calcPower(actualGrid)
        },
        actualTempTank1(){
            var {actualTemp1 = 0} = this.actualDeviceData || {}
            return actualTemp1.toFixed(0) + ' °C'
        },
        actualTempTank2(){
            var {actualTemp2 = 0} = this.actualDeviceData || {}
            return actualTemp2.toFixed(0) + ' °C'
        },
        // ******* POWER *******
        solarTotal(){
            //var {solarTotal = 0} = this.actualDeviceData
            //return calcPower(solarTotal)
            
            var {totalSolar = 0,
                actualSolar1 = 0,
                actualSolar2 = 0,
                actualSolar3 = 0,
                } = this.actualDeviceData || {}
            if (totalSolar < 1000) {   
                var result = totalSolar.toFixed(0) + ' ('
                   + actualSolar1.toFixed(0) + '/'
                   + actualSolar2.toFixed(0) + '/'
                   + actualSolar3.toFixed(0) + ') W'
            } else {
                var formatSettings = { style: 'decimal', minimumIntegerDigits:1, minimumFractionDigits:2, maximumFractionDigits:2 }
                var langCode = consts.locales[this.lang]?.code
                result = new Intl.NumberFormat(langCode, formatSettings).format(totalSolar/1000)  + ' ('
                 + new Intl.NumberFormat(langCode, formatSettings).format(actualSolar1/1000) + '/'
                 + new Intl.NumberFormat(langCode, formatSettings).format(actualSolar2/1000) + '/'
                 + new Intl.NumberFormat(langCode, formatSettings).format(actualSolar3/1000) + ') kW'
            }
            return result       
        },
        totalSolar(){
            var {totalSolar = 0} = this.actualDeviceData  || {}
            return this.calcPower(totalSolar)
        },
        actualSolar1(){
            var {actualSolar1 = 0} = this.actualDeviceData  || {}
            return this.calcPower(actualSolar1)
        },
        actualSolar2(){
            var {actualSolar2 = 0} = this.actualDeviceData  || {}
            return this.calcPower(actualSolar2)
        },
        actualSolar3(){
            var {actualSolar3 = 0} = this.actualDeviceData  || {}
            return this.calcPower(actualSolar3)
        },
        actualDetailsPower(){
            var {   
                    totalSolar = 0,
                    actualSolar1 = 0,
                    actualSolar2 = 0,
                    actualSolar3 = 0,
                } = this.actualDeviceData || {}
            if (totalSolar < 1000) {   
                var result = actualSolar1.toFixed(0) + ' / '
                   + actualSolar2.toFixed(0) + ' / '
                   + actualSolar3.toFixed(0) + ' W'
            } else {
                var formatSettings = { style: 'decimal', minimumIntegerDigits:1, minimumFractionDigits:2, maximumFractionDigits:2 }
                var langCode = consts.locales[this.lang]?.code
                result = new Intl.NumberFormat(langCode, formatSettings).format(actualSolar1/1000) + ' / '
                 + new Intl.NumberFormat(langCode, formatSettings).format(actualSolar2/1000) + ' / '
                 + new Intl.NumberFormat(langCode, formatSettings).format(actualSolar3/1000) + ' kW'
            }
            return result     
        },
        temp(){
            var {temp = 0} = this.actualDeviceData || {}
            return temp.toFixed(0) + ' °C'
        },
        // ******* BOTH *******
        actualInterval(){
            var {endTime = '0001-01-01'} = this.actualDeviceData || {}
            endTime = parseISO(endTime)
            //var lang = this.$i18n.i18next.language
            return format(endTime, 'Pp', {locale:consts.locales[this.lang]})
        },
        actualIntervalDate(){
            var {endTime = '0001-01-01'} = this.actualDeviceData || {}
            endTime = parseISO(endTime)
            return format(endTime, 'P', {locale:consts.locales[this.lang]})
        },
        actualIntervalTime(){
            var {endTime = '0001-01-01'} = this.actualDeviceData || {}
            endTime = parseISO(endTime)
            return format(endTime, 'p', {locale:consts.locales[this.lang]})
        },
        showActualError(){
            var {loadingError = null} = this.actualDeviceData || {}
            return loadingError
        },
        showActualData(){
            var result = (this.actualDeviceData != null) && (!this.actualDeviceData.loading) && (!this.actualDeviceData.loadingError)
            return result//this.actualDeviceData != null
        },
        showActualStandard(){
            console.log('ActualInfo selected device', this.selectedDevice)
            var showActualData = (this.actualDeviceData != null) && (!this.actualDeviceData.loading) && (!this.actualDeviceData.loadingError)
            console.log('showActualData', showActualData)
            return (showActualData) && (this.selectedDevice!=null) && (this.selectedDevice.deviceType == consts.DEVICE_TYPE_STANDARD) 
        },
        showActualPower(){
            console.log('ActualInfo selected device', this.selectedDevice)
            var showActualData = (this.actualDeviceData != null) && (!this.actualDeviceData.loading) && (!this.actualDeviceData.loadingError)
            return (showActualData) && (this.selectedDevice!=null) && (this.selectedDevice.deviceType == consts.DEVICE_TYPE_POWER) 
        },
        isActualValid(){
            var {endTime = '0001-01-01'} = this.actualDeviceData || {}
            endTime = parseISO(endTime)
            var minutesDifference = differenceInMinutes(endTime, new Date())
            console.log('act data minutes difference ',minutesDifference)
            return Math.abs(minutesDifference) < 60
        },
        actualSubtitleClass(){
            var {endTime = '0001-01-01'} = this.actualDeviceData || {}
            endTime = parseISO(endTime)
            var minutesDifference = differenceInMinutes(endTime, new Date())
            console.log('act data minutes difference ',minutesDifference)
            if (Math.abs(minutesDifference) > 60) return 'text-light-muted' 
            else return ''
        },
        langCode(){
            return consts.locales[this.lang]?.code
        }
        
        //get_language(){
        //  return this.$i18n.i18next.language
        //}
    },
    methods: {
        calcPower(power) {
            if (power == null) 
                power = 0
            if (power > 1000) {
                power = power/1000
                var formatSettings = { style: 'decimal', minimumIntegerDigits:1, minimumFractionDigits:2, maximumFractionDigits:2 }
                var langCode = consts.locales[this.lang]?.code
                var result = new Intl.NumberFormat(langCode, formatSettings).format(power) + ' kW'
            } else {
                result = power.toFixed(0) + ' W'
            }
            return result
        }
    },
    async created() {

    }    
}
</script>