import axios from 'axios'
//import i18next from 'i18next'
import i18n from '../../i18n'
const timeoutTimeMs = 90 * 24 * 60 * 60000 // 90 dni

const checkTokenTime = () => {
    var token = localStorage.getItem('tokenTime')
    return (token && (Date.now() - token) < timeoutTimeMs) 
}

const cleanLocalStorageUserData = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenTime')
    localStorage.removeItem('userRole')
    localStorage.removeItem('userEmail')    
    localStorage.removeItem('datasetHide0')    
    localStorage.removeItem('datasetHide1')    
    localStorage.removeItem('datasetHide2')    
    localStorage.removeItem('datasetHide3')    
}
export default {
    state: {
        user: null,
        userList: [],
        timeZones: [],
        message: "",
        error: ""
    },
    getters: {
        user: (state) => {
            return state.user
        },
        isAdmin: (state) => {
            
            var result = state.user && state.user.role == "Admin"
            console.log('user role ',state.user.role, result)
            return result
        },
        isLogged: (state) => {
            console.log("isLogged getter", state.user)
            var result = state.user && checkTokenTime() 
            console.log('user isLogged ', result)
            return result
        },
        userList: (state) => {
            return state.userList
        },
        timeZones: (state) => {
            return state.timeZones
        },
        message: (state) => {
            console.log("message getter", state.message)
            return state.message
        },

    },
    actions: {
        login: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                axios.post('login', payload)
                .then(({ data, status }) => {
                    if (status === 200) {
                        //console.log(axios)
                        console.log('post login', data);
                        localStorage.setItem('token', data.token)
                        localStorage.setItem('tokenTime', Date.now())
                        localStorage.setItem('userRole', data.userDetails.role)
                        localStorage.setItem('userEmail', data.userDetails.email)
                        
                        console.log(localStorage.getItem('token'))
                        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
                        commit('set_message', "")
                        commit('set_user', data.userDetails)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
        },
        logout(context, user) {
            // localStorage.removeItem('token')
            // localStorage.removeItem('tokenTime')
            // localStorage.removeItem('userRole')
            // localStorage.removeItem('userEmail')
            cleanLocalStorageUserData()
            axios.defaults.headers.common['Authorization'] = ``
            //console.log('logout context', context, context.rootGetters, i18n, i18next)
            //var trans = context.rootGetters.trans
            context.commit('set_user', user)
            context.commit('set_message', i18n.i18next.t('user_logged_out'))//"Uživatel byl odhlášen")
        },
        check_login_time(context){
            return new Promise((resolve) => {
                //var token = localStorage.getItem('tokenTime')
                //if (token && (Date.now() - token) > 2 * 60 * 1000) // 15 min
                if (!checkTokenTime())
                {
                    cleanLocalStorageUserData()
                    axios.defaults.headers.common['Authorization'] = ``
                    // localStorage.removeItem('token')
                    // localStorage.removeItem('tokenTime')
                    // localStorage.removeItem('userRole')
                    // localStorage.removeItem('userEmail')
                    
                    context.commit('set_user', {})
                    context.commit('set_message', i18n.i18next.t('user_logged_out_inactivity'))//"Odhlášeno z důvodu nečinnosti")
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
        },
        refresh: () => {
            return new Promise((resolve, reject) => {
                axios.get('login/refresh')
                .then(({ data, status }) => {
                    if (status === 200) {
                        //console.log(axios)
                        console.log('get login/refresh', data);
                        localStorage.setItem('token', data.token)
                        localStorage.setItem('tokenTime', Date.now())
                        console.log(localStorage.getItem('token'))
                        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
        },
        register: (context, payload) => {
            return new Promise((resolve, reject) => {
                axios.post('user', payload)
                .then(({ data, status }) => {
                    if (status === 200) {
                        //console.log(axios)
                        console.log('post user', data);
                        //localStorage.setItem('token', data.token)
                        //localStorage.setItem('userRole', data.userDetails.role)
                        //localStorage.setItem('userEmail', data.userDetails.email)
                        
                        //console.log(localStorage.getItem('token'))
                        //axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
                        //commit('set_user', data.userDetails)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    console.log('register failed', error);
                    reject(error);
                  });
            });
        },
        get_user: ({ commit }) => {
            /*return new Promise((resolve, reject) => {
                axios.get('user')
                .then(({ data, status }) => {
                    if (status === 200) {
                        console.log('user data', data)
                        commit('set_user', data)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });*/
            var userRole = localStorage.getItem('userRole')
            var userEmail = localStorage.getItem('userEmail')
            commit('set_user', {role: userRole, email: userEmail})
            return true
          },  
        set_user(context, user) {
            context.commit('set_user', user)
        },
        get_user_list(context) {
            return new Promise((resolve, reject) => {
                axios
                  .get('user')
                  .then(({ data, status }) => {
                    if (status === 200) {
                      console.log(data);
                      context.commit('set_user_list', data)  
                      resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
              });
        },
        update_user: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                var payloadData = {id: payload.id, timeZone: payload.timeZone}
                axios.put('user', payloadData)
                .then(({ data, status }) => {
                    if (status === 204) {
                        console.log(axios)
                        console.log(data);
                        commit('update_user', payloadData)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
        },
        delete_user: ({ commit }, id) => {
            return new Promise((resolve, reject) => {
                axios.delete('user/'+id)
                .then(({ data, status }) => {
                    if (status === 200) {
                        console.log(axios)
                        console.log(data);
                        commit('delete_user', id)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
        },
        get_timezones(context) {
            return new Promise((resolve, reject) => {
                axios
                  .get('utils/timezones')
                  .then(({ data, status }) => {
                    if (status === 200) {
                      console.log(data);
                      context.commit('set_timezones', data.cTimeZones)  
                      resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
              });
        },
        set_message(context, message) {
            context.commit('set_message', message)
        },
        get_profile: (context) => {
            return new Promise((resolve, reject) => {
                axios.get('profile')
                .then(({ data, status }) => {
                    if (status === 200) {
                        console.log('user data', data)
                        var user = {
                            id: data.id,
                            timeZone: data.timeZone,
                            role: context.state.user.role,
                            email: context.state.user.email
                        }
                        context.commit('set_user', user)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
            //var userRole = localStorage.getItem('userRole')
            //var userEmail = localStorage.getItem('userEmail')
            //commit('set_user', {role: userRole, email: userEmail})
            //return true
          }, 
        update_profile: (context, payload) => {
            return new Promise((resolve, reject) => {
                var payloadData = {id: payload.id, timeZone: payload.timeZone}
                axios.put('profile', payloadData)
                .then(({ data, status }) => {
                    if (status === 204) {
                        console.log(axios)
                        console.log(data);
                        var user = {
                            id: payload.id,
                            role: context.state.user.role,
                            timeZone: payload.timeZone,
                            email: payload.email
                        }
                        context.commit('set_user', user)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
        },   
    },
    mutations: {
        set_user(state, user) {
            console.log('mutation set_user', user)
            state.user = user
        },
        set_user_list(state, userList) {
            state.userList = userList
        },
        delete_user(state, id) {
            //console.log(newDevice)
            let userIdx = state.userList.findIndex(obj => obj.id == id);
            if (userIdx >- 1){
                // odebrat sim ze seznamu
                state.userList.splice(userIdx, 1);
                
            }
        },
        update_user(state, user) {
            console.log('mutation update_user', user)
            var {id, timeZone} = user
            let userIdx = state.userList.findIndex(obj => obj.id == id);
            if (userIdx >- 1){
                // zmenit udaje
                state.userList[userIdx].timeZone = timeZone
                //state.userList[userIdx].TimeZone = timeZone
                
            }
        },
        set_timezones(state, zones) {
            console.debug('mutation set_timezones', zones)
            state.timeZones = zones
        },
        set_message(state, message) {
            console.log('mutation set_message', message)
            state.message = message
        },

    }
}

