<template>
    <b-form>
        <b-form-select id="device" name="devices" v-model="selectedDevice" @change="onChange($event)">
            <b-form-select-option :value="null" disabled>{{$t('select_device')}}</b-form-select-option>
            <b-form-select-option :value="'all'">{{$t('all')}}</b-form-select-option>
            <b-form-select-option :value="device.id" v-for="device in deviceList" :key="device.id">{{device.name}}</b-form-select-option>
        </b-form-select>
    </b-form>    
</template>

<script>
//        <b-form-select id="device" v-model="selected" :options="deviceList"></b-form-select>
//<b-form-select id="device" name="devices" v-model="selectedDevice" @change="onChange($event)">
import {mapGetters} from 'vuex'

export default {
    name: 'DeviceListSelect',
    data() {
      return {
        //selected: null
      }
    },
    //props: ['deviceList', 'selectedDevice'],
    computed: {
        ...mapGetters(['selectedDeviceId', 'deviceList']),
        selectedDevice: {
            get() {
                console.log('selectedDeviceId computed', this.selectedDeviceId)
                return this.selectedDeviceId
            },
            set(value) {
                console.log('selected device value', value)
                this.$emit('deviceSelected',value)
            }
        }
    },
    methods: {
        onChange(/*event*/) {
            //console.log(event)
            //console.log('selected device event', event.target.value)
            //this.$emit('deviceSelected',event)
        }
    }    
}
</script>

