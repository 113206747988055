<template>
    <div>
        <!--<b-row v-if="errors.length>0">
            <b-col>
                <stats-card class="mb-2 mb-md-4"
                    :title="$t('kerberos_errors')"
                    :show-footer-line="true">
                    <template slot="footer">
                        <div class="d-flex flex-row ml-1 align-items-center">
                            <div class="d-flex flex-column bd-highlight mb-1 mr-1 mt-1 p-2" style="font-size: 20px;">
                                    <b-icon icon="exclamation-triangle-fill" scale="2" variant="danger"></b-icon>
                            </div>
                            <div class="d-inline-flex ml-2">
                                <ul class="mb-0">
                                    <li v-for="error in errors" :key="error">
                                        {{ $t(error) }} 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </template>
                </stats-card>
            </b-col>
        </b-row>-->
        <b-row v-if="errorsArr.length>0">
            <b-col>
                <stats-card class="mb-2 mb-md-4"
                    :title="$t('kerberos_errors')"
                    :show-footer-line="true">
                    <template slot="footer">
                        <div class="d-flex flex-row ml-1 align-items-center">
                            <div class="d-flex flex-column bd-highlight mb-1 mr-1 mt-1 p-2" style="font-size: 20px;">
                                    <b-icon icon="exclamation-triangle-fill" scale="2" variant="danger"></b-icon>
                            </div>
                            <div class="d-inline-flex ml-2">
                                <ul class="mb-0">
                                    <li v-for="error in errorsArr" :key="error">
                                        {{ $t(error) }} 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </template>
                </stats-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
/*
                    type="white"
                    icon="exclamation-triangle"
                    iconColor="red" 

*/
import consts from '../../consts.js'
import StatsCard from '../Common/StatsCard';
import {mapGetters} from 'vuex'
import { format, isAfter, parseISO, startOfToday } from 'date-fns'

const errTexts = [
    'kerberos_error.sensor_error',
    'kerberos_error.load_error',
    'kerberos_error.insulation_error',
    'kerberos_error.overheat_error',
    'kerberos_error.thermal_fuse_error',
    'kerberos_error.resistance_error',
    'kerberos_error.fan_error',
    'kerberos_error.other_error',
]
export default {
    name: 'DeviceError',
    components: {
        StatsCard,
    },
    data() {
        return {

        }
    },
    props: ['actualDeviceData'],
    computed: {
        ...mapGetters(['lang']),
        /*errors(){
            try {
                var result = []
                var {errorCode = "0"} = this.actualDeviceData || {}
                //var errorCode = '255'
                errorCode = parseInt(errorCode)
                //errorCode = Math.floor(Math.random() * 255);
                if (errorCode>0) {
                    for (var i=0;i<8;i++){
                        if ((errorCode>>i) & 1 == 1) result.push(errTexts[i])
                    }
                }
                console.log('device errors', result)
                return result 
            } catch (error) {
                return []                
            }
        },*/
        errorsArr(){ // tohle je spravne
            try {
                var result = []
                var {errorCode = ""} = this.actualDeviceData || {}
                if (errorCode !=""){
                    var errorCodeArr = errorCode.split(',')
                    console.log('errorCodeArr',errorCodeArr)
                    if (errorCodeArr.length>0) {
                        for (var i=0; i< errorCodeArr.length; i++){
                            var errInt = parseInt(errorCodeArr[i].trim())
                            console.log('errorCode',errInt)
                            if (errInt==6) continue; // skip fan error
                            result.push(errTexts[errInt])
                        }
                    }
                }
                // sim expired
                var {displayEndOfSim = false} = this.actualDeviceData || {}
                var {endOfSimDate = '0001-01-01'} = this.actualDeviceData || {}
                if (displayEndOfSim) {
                    endOfSimDate = parseISO(endOfSimDate)
                    console.log('endOfSimDate', endOfSimDate, startOfToday(), isAfter(startOfToday(), endOfSimDate))
                    if (isAfter(startOfToday(), endOfSimDate)) {
                        var endOfSimDateStr = format(endOfSimDate, 'P', {locale:consts.locales[this.lang]})
                        result.push(this.$t('sim_expired') + endOfSimDateStr)
                    }    
                }
                console.log('device errors', result)
                return result 
            } catch (error) {
                return []                
            }
        },
        /*errorsArr(){ // random errors pro zkoušení
            try {
                var result = []
                var {errorCode = "0"} = this.actualDeviceData || {}
                //var errorCode = '255'
                //errorCode = parseInt(errorCode)
                errorCode = Math.floor(Math.random() * 255);
                if (errorCode>0) {
                    for (var i=0;i<8;i++){
                        if ((errorCode>>i) & 1 == 1) result.push(errTexts[i])
                    }
                }
                // sim expired
                var {displayEndOfSim = false} = this.actualDeviceData || {}
                var {endOfSimDate = '0001-01-01'} = this.actualDeviceData || {}
                if (displayEndOfSim) {
                    endOfSimDate = parseISO(endOfSimDate)
                    console.log('endOfSimDate', endOfSimDate, startOfToday(), isAfter(startOfToday(), endOfSimDate))
                    if (isAfter(startOfToday(), endOfSimDate)) {
                        var endOfSimDateStr = format(endOfSimDate, 'P', {locale:consts.locales[this.lang]})
                        result.push(this.$t('sim_expired') + endOfSimDateStr)
                    }    
                }

                console.log('device errors', result)
                return result 
            } catch (error) {
                return []                
            }
        },*/

    }
}
</script>
<style scoped>
ul {
  list-style: none; 
  padding: 0;
  margin: 0;
}

li {
  padding-left: 1rem;
}

li::before {
  content: "•"; 
  padding-right: 0.5rem;
  color: rgb(143, 143, 145); 
  
}

</style>