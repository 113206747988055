<template>
    <form @submit.prevent="handleSubmit">
        <Error v-if="error" :error="error"/>

        <h3>{{ $t("reset_password") }}</h3>
        <b-form-group
                :label="$t('password')"
                label-for="password-input"
                :invalid-feedback="passwordFeedback"
                :state="passwordState"
                >
                <b-form-input type="password" class="form-control" v-model="password" :placeholder="$t('password')" required/>
        </b-form-group>

        <b-form-group
                :label="$t('confirm_password')"
                label-for="passwordagain-input"
                :invalid-feedback="passwordagainFeedback"
                :state="passwordagainState"
                >
                <b-form-input type="password" class="form-control" v-model="passwordagain" :placeholder="$t('confirm_password')" />
        </b-form-group>

        <button class="btn btn-primary btn-block">{{ $t("submit") }}</button>
    </form>      
</template>
<script>
import axios from 'axios'
import Error from './Error'

export default {
    name: 'Reset',
    components: {
        Error,
    },
    data() {
        return {
            error: '',
            password: '',
            passwordagain: '',
            passwordState: null,
            passwordagainState: null,
            passwordFeedback: '',
            passwordagainFeedback: ''

        }
    },
    watch: {
        password: function() {
            this.checkPasswordValidity()
        },
        passwordagain: function() {
            this.checkPasswordAgainValidity()
        },
        
    },
    methods: {
        checkPasswordValidity() {
            var valid = true
            this.password = this.password.trim()
            var pwdRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; //"^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
            valid = pwdRegex.test(this.password);
            if (!valid)
                this.passwordFeedback = this.$t('password_bad_format')
            this.passwordState = valid
            return valid
        },
        checkPasswordAgainValidity() {
            this.passwordagain = this.passwordagain.trim()
            this.passwordagainState = this.password == this.passwordagain
            if (this.password != this.passwordagain) {
                this.passwordagainFeedback = this.$t('password_not_same')
            } else this.passwordagainFeedback = null
            console.log('Password again state', this.passwordagainState)
            return this.passwordagainState
        },
        checkFormValidity() {
            console.log('Validate start')
            var valid = this.checkPasswordAgainValidity() 
            valid &= this.checkPasswordValidity()
            return valid
        },
        parseFormErrors(errors){
            console.log('parse errors', errors)
            this.passwordState = errors.Password == true
            if (typeof(errors.Password) == 'object')
                this.passwordFeedback = errors.Password[0]
        },
        async handleSubmit(){
            try {
                if (!this.checkFormValidity()) {
                        return false
                    }
                const data = {
                    password: this.password,
                    passwordagain: this.passwordagain,
                    token: this.$route.params.token 
                }
                //console.log('submitted data', data)
                //const response = await axios.post('reset', data)
                //{ "Token": "1d411eddd6b1d3608b044dc67fcaddfc10f74085", "Password": "kerberos1", "PasswordAgain": "kerberos1"}
                axios.post('user/reset/', data)
                    .then((success) => {
                        console.log(success)
                        if (success && success.status === 200) {
                            console.log(success.data);
                            //context.commit('set_user_list', data)  
                            this.$store.dispatch("set_message", this.$t('password_changed'))
                            this.$router.push('/login')
                        } else {
                            alert(this.$t('reset_token_expired'))
                            console.log(success); 
                        } 
                    })
                    .catch(error => {
                        if (error && error.response && error.response.data) {
                            if (typeof(error.response.data)== 'string') {
                                // user exists
                                if (error.response.data.includes("už existuje")) // to bude asi jine
                                    this.error = this.$t("user_already_exists")
                                else
                                    this.error = error.response.data
                            } else {
                                // some of parameter wrong
                                if (error.response.data.errors) {
                                    this.parseFormErrors(error.response.data.errors)
                                } else {
                                    this.error = error.response.data
                                }
                            }
                        } else {
                            this.error = error
                        }
                        /*if (error) {
                            if (error.response) {
                                // The request was made and the server responded with a status code
                                // that falls out of the range of 2xx
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                            } else if (error.request) {
                                // The request was made but no response was received
                                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                // http.ClientRequest in node.js
                                console.log(error.request);
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                console.log('Error', error.message);
                            }
                            console.log(error.config);
                        }
                        alert(error);*/
                    });
            } catch (e){
                this.error = this.$t('error_occured')
            }
        }
    }
}
</script>