<template>
    <div class="auth-wrapper ">
    <Message v-if="message" :message="message"/>
      <div class="auth-inner">
        <router-view />
      </div>
    </div>      
</template>
<script>
import Message from './Message'
import {mapGetters} from 'vuex'
export default {
    name: 'Auth',
    components: {
        //Error,
        Message
    },
    computed: {
        ...mapGetters(['message']),
    },
}
</script>