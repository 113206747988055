<template>
    <div>
        <b-card title="" v-if="deviceList" class="mb-2">
            <h5 class="card-title text-uppercase text-muted mb-2">{{ $t('current_state') }} </h5>
            <b-row >
                <b-col>
                    <b-table v-if="deviceList" 
                        striped 
                        hover 
                        :items="tableDataStd" 
                        :fields="fieldsStd"
                        stacked="sm"
                        >
                        <template #cell(device)="row">
                            <div class="d-flex align-middle justify-content-between">
                                <div class="d-inline-flex align-middle my-auto">{{row.item.device}}</div>
                                <div class="d-inline-flex">
                                    <b-button size="sm" @click.prevent="goDetail(row.item.id)" variant="none">
                                        <b-icon icon="card-text" scale="1" variant="none"></b-icon>
                                    </b-button>
                                </div>
                            </div>
                            
                        </template>
                        <template #cell(errorCode)="row">
                           <!-- <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
                            Info modal
                            </b-button>-->
                           <b-button v-if="row.item.isError" size="sm" @click="row.toggleDetails" variant="none">
                                <b-icon icon="exclamation-triangle-fill" scale="1" variant="danger"></b-icon>
                            </b-button>
                           <b-button v-if="row.item.isWarning&&!row.item.isError " size="sm" @click="row.toggleDetails" variant="none">
                                <b-icon icon="exclamation-triangle-fill" scale="1" variant="warning"></b-icon>
                            </b-button>
                            <span v-if="isHeatingSolar(row.item)"><b-icon icon="sun" scale="1" variant="warning"></b-icon></span>
                            <span v-if="isHeatingGrid(row.item)"><b-icon icon="lightning" scale="1" variant="primary"></b-icon></span>
                        </template>
                        
                        <template #row-details="row">
                            <DeviceWarning :actualDeviceData="row.item"/>
                            <DeviceError :actualDeviceData="row.item"/>
                            <!--<b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                            </ul>
                            </b-card>-->
                        </template>
                    </b-table>
                    <!-- Info modal -->
                    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
                    <pre>{{ infoModal.content }}</pre>
                    </b-modal>
                </b-col>
            </b-row >
        </b-card>
    </div>
</template>

<script>
/*
<!--<b-icon icon="card-text" scale="1" variant="none"></b-icon> graph-up record-circle-->
<!--<b-icon icon="card-text" scale="1" variant="none"></b-icon>-->
{"actualSolar":46.905537459283394,
"actualGrid":0.0,
"actualTemp1":36.0,
"actualTemp2":0.0,
"heatingState":0,
"errorCode":"6",
"startTime":"2022-09-14T07:23:42",
"endTime":"2022-09-14T07:28:49"}
{"totalSolar":0.0,
"actualSolar1":0.0,
"actualSolar2":0.0,
"actualSolar3":0.0,
"temp":0.0,
"errorCode":null,
"heatingState":0,
"startTime":"0001-01-01T02:00:00",
"endTime":"0001-01-01T02:00:00"}
*/
import {mapGetters} from 'vuex'
import {parseISO, differenceInMinutes, isAfter, startOfToday } from 'date-fns'

import 'chartjs-adapter-date-fns';
import consts from '../../consts.js'
import DeviceError from './DeviceError.vue'
import DeviceWarning from './DeviceWarning.vue'

export default {
    name: 'ActualInfoAll',
    components: {
        DeviceError,
        DeviceWarning
    },
    data() {
        return {
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
                }
        }
    },
    props: ['sensorData'],
    computed: {
        ...mapGetters(['user', 'lang', 'actualDeviceDataList', 'deviceList']),
        ...mapGetters(['actualDeviceData','deviceData', 'filterType', 'filterFrom', 'filterTo', 'selectedDeviceId', 'selectedDevice']),
        fieldsStd() {
            return  [
                {
                    key: 'device',
                    label: this.$t('device_name'),
                    sortable: true,
                    class: "align-middle"
                },
                {
                    key: 'actualSolar',
                    label: this.$t('solar') +  ' [kW]',
                    sortable: true,
                    class: "align-middle"
                },
                {
                    key: 'actualGrid',
                    label: this.$t('grid') +  ' [kW]',
                    class: "align-middle",
                    sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    //variant: 'danger'
                },
                {
                    key: 'actualTemp1',
                    label: this.$t('temperature') + " 1" + ' [°C]',
                    class: "align-middle",
                    sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    //variant: 'danger'
                },
                {
                    key: 'actualTemp2',
                    label: this.$t('temperature') + " 2" + ' [°C]',
                    class: "align-middle",
                    sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    //variant: 'danger'
                },
                {
                    key: 'errorCode',
                    label: '',//this.$t('errors'),
                    class: "align-middle"
                    //sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    //variant: 'danger'
                },
                /*{
                    key: 'actions',
                    label: '',//this.$t('errors'),
                    class: "align-middle"
                    //sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    //variant: 'danger'
                }*/
            ]
        },
        tableDataStd(){
            // {"actualSolar":46.905537459283394,
            // "actualGrid":0.0,
            // "actualTemp1":36.0,
            // "actualTemp2":0.0,
            // "heatingState":0,
            // "errorCode":"6",
            // "startTime":"2022-09-14T07:23:42",
            // "endTime":"2022-09-14T07:28:49"}
            var tableDataList = []
            this.deviceList.forEach((device)=>{
                console.log('Selected device (table)', device)
                var {deviceType, id, name} = device || {}
                var formatSettings = { style: 'decimal', minimumIntegerDigits:1, minimumFractionDigits:3, maximumFractionDigits:3 }
                var langCode = consts.locales[this.lang]?.code
                if (this.actualDeviceDataList!=null){
                    var data = this.actualDeviceDataList.find(element => { if (element) return element.id == id; else return false})
                    if (data ) {
                        var {errorCode = "",
                            endTime, 
                            heatingState = 0,
                            displayEndOfSim = false,
                            endOfSimDate = '0001-01-01'
                            } = data || {}
                        if (errorCode=="6") errorCode = ""; // remove fan error, if it is the only error    
                        // check sim date
                        var isWarning = false
                        var isError = errorCode || false
                        if (displayEndOfSim) { // chyba nebo warn
                            var endOfSimDateParsed = parseISO(endOfSimDate)
                            if (!isAfter(startOfToday(), endOfSimDateParsed)) {
                                isWarning = true
                                //var endOfSimDateStr = format(endOfSimDate, 'P', {locale:consts.locales[this.lang]})
                                //result.push(this.$t('sim_expiring') + endOfSimDateStr)
                            } else {  
                                isError = true 
                            }
                        }
                        if (deviceType == consts.DEVICE_TYPE_STANDARD) {  
                        var {actualSolar = 0,
                            actualGrid = 0,
                            actualTemp1 = 0,
                            actualTemp2 = 0,
                            } = data || {}
                            actualSolar = new Intl.NumberFormat(langCode, formatSettings).format(actualSolar/1000)
                            actualGrid = new Intl.NumberFormat(langCode, formatSettings).format(actualGrid/1000)
                            var items = { 
                                device: name, 
                                actualSolar, 
                                actualGrid, 
                                actualTemp1, 
                                actualTemp2, 
                                errorCode, 
                                id, 
                                endTime, 
                                heatingState,
                                displayEndOfSim,
                                endOfSimDate,
                                isWarning,
                                isError }

                            console.log('TableData standard items', items)
                            tableDataList.push(items)
                        }
                        else if (deviceType == consts.DEVICE_TYPE_POWER) {
                            var {totalSolar = 0,
                            /* actualSolar1 = 0,
                            actualSolar2 = 0,
                            actualSolar3 = 0,*/
                            temp = 0,
                            } = data || {}
                        
                            totalSolar = new Intl.NumberFormat(langCode, formatSettings).format(totalSolar/1000)
                            /*actualSolar1 = (actualSolar1/1000).toFixed(3)
                            actualSolar2 = (actualSolar2/1000).toFixed(3)
                            actualSolar3 = (actualSolar3/1000).toFixed(3)*/
                            items = { 
                                device: name, 
                                actualSolar:totalSolar, 
                                actualGrid:null, 
                                actualTemp1:temp, 
                                errorCode, 
                                id, 
                                endTime, 
                                heatingState,
                                displayEndOfSim,
                                endOfSimDate,
                                isWarning,
                                isError }
                            console.log('TableData power items',items)
                            tableDataList.push(items)
                        }
                    }
                }
            })
            console.log('ActualInfoAll table stdDataList', tableDataList)
            return tableDataList
        },
    },
    methods: {
        isHeatingSolar(item) {
            return item.heatingState == consts.HEATING_STATE_SOLAR
        },
        isHeatingGrid(item) {
            return item.heatingState == consts.HEATING_STATE_GRID
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        goDetail(id) {
            this.$store.dispatch("set_selected_device_id", id)
            //this.$router.go('/dashboard/'+id)
            //this.$router.push('/dashboard/'+id)
            console.log('go to the detail device', id)
            //this.$emit('deviceSelected',id)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        actualSubtitleClass(item){
            var {endTime = '0001-01-01'} = item || {}
            endTime = parseISO(endTime)
            var minutesDifference = differenceInMinutes(endTime, new Date())
            console.log('act data minutes difference ',minutesDifference)
            if (Math.abs(minutesDifference) > 60) return 'text-light-muted' 
            else return ''
        },
    },
    async created() {
        //this.getDeviceData()
    }    
}
</script>