import axios from 'axios'

//axios.defaults.baseURL = 'https://192.168.1.130:5001/api/' //'http://localhost:8000';
axios.defaults.baseURL = '/api/'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')



export default axios
