//import {cs} from 'date-fns/locale';
// MixChart.js
import { Line } from 'vue-chartjs/legacy'
//import HtmpLegend from './htmlLegendPlugin'
/*import LegendClick from './clickPlugin'
import LegendHover from './hoverPlugin'
import LegendBottomMargin from './bottomMarginPlugin'*/
//import zoomPlugin from 'chartjs-plugin-zoom'
import {
  Chart,
  //ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  //BubbleController,
  //DoughnutController,
  LineController,
  //PieController,
  //PolarAreaController,
  //RadarController,
  //ScatterController,
  CategoryScale,
  LinearScale,
  //LogarithmicScale,
  //RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  //Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  
} from 'chart.js';
Chart.register(
  //ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  //BubbleController,
  //DoughnutController,
  LineController,
  //PieController,
  //PolarAreaController,
  //RadarController,
  //ScatterController,
  CategoryScale,
  LinearScale,
  //LogarithmicScale,
  //RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  //Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  //HtmpLegend,
  //LegendClick,
  //LegendHover,
  //LegendBottomMargin(20),
  //zoomPlugin
);
//const { reactiveProp } = mixins
import 'chartjs-adapter-date-fns';

export default {
  extends: Line,
  //mixins: [reactiveProp],
  props: ['options', 'chartData'],
  mounted () {
    // Overwriting base render method with actual data.
    console.log('Chart mounted');
    console.log('Chart data', this.chartData);
    console.log('Chart options', this.options);

    this.renderChart(this.chartData, this.options)
  },
  watch: {
    options: function() {
      console.log('Chart watcher')
      console.log('Chart data', this.chartData);
      console.log('Chart options', this.options);
      this.$data._chart.destroy();
      //this.renderChart(this.data, this.options);
      this.renderChart(this.chartData, this.options);
    },
    chartData: function() {
      console.log('Chart watcher data')
      console.log('Chart data', this.chartData);
      console.log('Chart options', this.options);
      this.$data._chart.destroy();
      //this.renderChart(this.data, this.options);
      this.renderChart(this.chartData, this.options);
    }
  },
}

/*
const chart = new Chart(ctx, {
  type: 'line',
  data: {
    labels: ['Friday', 'Saturday', 'Sunday', 'Monday'],
    datasets: [
      {
        yAxisID: 'A', // <-- the Y axis to use for this data set
        label: 'Page Views',
        data: [13500, 5700, 6300, 8200],
        borderWidth: 1,
        backgroundColor: 'blue',
        borderColor: 'blue'
      },
      {
        yAxisID: 'B', // <-- the Y axis to use for this data set
        label: 'Revenue',
        data: [11, 3.6, 7.3, 8.1],
        backgroundColor: 'green',
        borderColor: 'green'
      }
    ]
  },
  options: {
    responsive: true,
    scales: {
      A: {
        type: 'linear',
        position: 'left',
        ticks: { beginAtZero: true, color: 'blue' },
        // Hide grid lines, otherwise you have separate grid lines for the 2 y axes
        grid: { display: false }
      },
      B: {
        type: 'linear',
        position: 'right',
        ticks: { beginAtZero: true, color: 'green' },
        grid: { display: false }
      },
      x: { ticks: { beginAtZero: true } }
    }
  }
});



var mixedChart = new Chart(ctx, {
  type: 'bar',
  data: {
      datasets: [{
          label: 'Bar Dataset',
          data: [10, 20, 30, 40]
      }, {
          label: 'Line Dataset',
          data: [50, 50, 50, 50],

          // Changes this dataset to become a line
          type: 'line'
      }],
      labels: ['January', 'February', 'March', 'April']
  },
  options: options
});*/