import Vue from 'vue'
import i18next from 'i18next'
import VueI18Next from '@panter/vue-i18next'
import locale_en from './lang/en'
import locale_cs from './lang/cs'

var defLang = navigator.language || navigator.userLanguage
if (defLang=='cs-CZ') defLang = 'cs'
var lang = localStorage.getItem('lang') 
if (!lang) {
  lang = defLang || 'en'
  localStorage.setItem('lang', lang) 
}

i18next.init({
    lng: lang,
    fallbackLng: 'en',
    whitelist: ['en', 'cs', 'fr'],
    resources: {
      cs:locale_cs,//{ translation: locales.cs },
      en:locale_en//{ translation: locales.en }
    }
  });
  
  Vue.use(VueI18Next)
  const i18n = new VueI18Next(i18next);

  export default i18n