<template>
    <form @submit.prevent="handleSubmit">
        <h3>{{ $t("register_after.heading") }}</h3>
        <p></p>
        <p>{{ $t("register_after.text1") }}</p>
        <p>{{ $t("register_after.text2") }}</p>
        <p></p>
        <button class="btn btn-primary btn-block">{{ $t("login") }}</button>
    </form>
</template>
<script>

export default {
    name: 'RegisterAfter',
    data() {
        return {
            
        }
    },
    methods: {
        handleSubmit() {
            this.$router.push("/login")
        }
    },
}
</script>