<template>
    <b-table v-if="selectedDevice" striped :items="tableData" :fields="fields"></b-table>
</template>

<script>
import {mapGetters} from 'vuex'
//import { format, parseISO } from 'date-fns'

import 'chartjs-adapter-date-fns';
import consts from '../../consts.js'
export default {
    name: 'ConsumptionTable',
    components: {
    },
    data() {
        return {
        //     fields: [
        //         // {
        //         //     key: 'device',
        //         //     label: this.$t('device'),
        //         //     //sortable: true
        //         // },
        //         {
        //             key: 'power',
        //             label: this.$t('solar') +  ' [kWh]',
        //             //sortable: false
        //         },
        //         {
        //             key: 'consumption',
        //             label: this.$t('grid') +  ' [kWh]',
        //             //sortable: true,
        //             // Variant applies to the whole column, including the header and footer
        //             //variant: 'danger'
        //         }
        //     ],
        }
    },
    props: ['sensorData'],
    computed: {
        ...mapGetters(['user', 'lang']),
        ...mapGetters(['actualDeviceData','deviceData', 'filterType', 'filterFrom', 'filterTo', 'selectedDeviceId', 'selectedDevice']),
        fields() {
            var device = this.selectedDevice
            console.log('Selected device (table)', device)
            //var name = ""
            //if (device && device.name ) 
            //    name = device.name

            var fieldsPower =  [
                // {
                //     key: 'device',
                //     label: this.$t('device'),
                //     //sortable: true
                // },
                {
                    key: 'totalSolar',
                    label: this.$t('solar_total') +  ' [kWh]',
                    //sortable: false
                },
                {
                    key: 'totalSolar1',
                    label: this.$t('solar') +  '1 [kWh]',
                    //sortable: false
                },
                {
                    key: 'totalSolar2',
                    label: this.$t('solar') +  '2 [kWh]',
                    //sortable: false
                },
                {
                    key: 'totalSolar3',
                    label: this.$t('solar') +  '3 [kWh]',
                    //sortable: false
                },
            ]
            var fieldsStd =  [
                // {
                //     key: 'device',
                //     label: this.$t('device'),
                //     //sortable: true
                // },
                {
                    key: 'power',
                    label: this.$t('solar') +  ' [kWh]',
                    //sortable: false
                },
                {
                    key: 'consumption',
                    label: this.$t('grid') +  ' [kWh]',
                    //sortable: true,
                    // Variant applies to the whole column, including the header and footer
                    //variant: 'danger'
                }
            ]
            var {deviceType} = this.selectedDevice
            if (deviceType == consts.DEVICE_TYPE_POWER) 
                return fieldsPower    
            else
                return fieldsStd

        },
        tableData(){
            var device = this.selectedDevice
            console.log('Selected device (table)', device)
            //var name = ""
            //if (device && device.name ) 
            //    name = device.name
            var {deviceType} = this.selectedDevice
            if (deviceType == consts.DEVICE_TYPE_POWER) {
                var {totalSolar = 0,
                    totalSolar1 = 0,
                    totalSolar2 = 0,
                    totalSolar3 = 0} = this.deviceData || {}
                
                totalSolar = (totalSolar/1000).toFixed(3)
                totalSolar1 = (totalSolar1/1000).toFixed(3)
                totalSolar2 = (totalSolar2/1000).toFixed(3)
                totalSolar3 = (totalSolar3/1000).toFixed(3)
                var items = [{ /*device: name,*/ totalSolar, totalSolar1, totalSolar2, totalSolar3 }]
                console.log('TableData items',items)
                return items
            }
            else {
                var power = 0
                var consumption = 0    
                if (this.deviceData !== null) {
                    if (this.deviceData.totalSolarEnergy)
                        power = this.deviceData.totalSolarEnergy
                    if (this.deviceData.totalGridEnergy)
                        consumption = this.deviceData.totalGridEnergy
                    // this.deviceData.powerFromSolar.forEach((item) => {
                    //     if (item){
                    //         power += item
                    //     }
                    // })
                    // this.deviceData.powerFromGrid.forEach((item) => {
                    //     if (item){
                    //         consumption += item
                    //     }
                    // })
                }
                var formatSettings = { style: 'decimal', minimumIntegerDigits:1, minimumFractionDigits:3, maximumFractionDigits:3 }
                var langCode = consts.locales[this.lang]?.code
                power = new Intl.NumberFormat(langCode, formatSettings).format(power/1000)
                consumption = new Intl.NumberFormat(langCode, formatSettings).format(consumption/1000)
                //power = (power/1000).toFixed(3)
                //consumption = (consumption/1000).toFixed(3)
                var stditems = [{ /*device: name,*/ power: power, consumption: consumption }]
                console.log('TableData items',stditems)
                return stditems  
            }
        },
    },
    methods: {

    },
    async created() {
        //this.getDeviceData()
    }    
}
</script>