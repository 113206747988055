<template>
  <div id="app" class="container">
    <Nav />
    <router-view :key="$route.fullPath"/>
  </div>

</template>

<script>
import Nav from './components/Nav.vue'

export default {
  name: 'App',
  components: {
    Nav,
    
  },
  methods:{
    get_user() {
            this.$store.dispatch("get_user")
            .then(success => {
                console.log("get_user",success)
            })
            .catch(error => {
                console.log("get_user error",error)
            })
        }
  },
  async created() {
    //this.$store.dispatch("set_i18n", this.$i18n)
    this.get_user()
  }  
}
</script>
  
<style>
 /* @import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');*/
 /*@font-face {
  font-family: 'myriad-web';
  src: url('assets/myriadweb.ttf');
}*/
  * {
    box-sizing: border-box;
  }

  body {
    background: #eaf1fa !important;
    min-height: 100vh;
    display: flex;
 /*   font-weight: 400;
    font-family: 'Fira Sans', sans-serif;*/
  }

  .content {
    padding: 1rem 0 0;
  }

  /* for fixed menu on top
  .content {
    padding: 4.5rem 0 0;
  }*/

  /*.kerberos-brand {
    font-family: myriad-pro, myriad-web, sans-serif;
    font-weight: 500;
    font-size: 200%;
    color:#322e59 !important;
  }
  */
  .kerberos-brand {
    height: 20px;
    margin-left: 10px;
    max-width: 40vw;
  }

  .kerberos-logo2 {
    height: 4rem;
    
  }
  .kerberos-logo {
    height: 2rem;
    
  }

  .background-light {
    background: #fdfdfe !important;
  }

 /* h1, h2, h3, h4, h5, label, span {
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
  }
*/
  body, html, #app, #root {
    /*width: 100%;*/
    height: 100%;
    min-width: 320px;
  }

  .auth-wrapper {
    width: 100%;
    /*min-height: 50vh;*/
    min-width: 320px;
    height: calc(100% - 4.5rem);
  }

  /*#app {
    text-align: center;
  }*/
  .chart-box {
    height: 350px;
  }
  .chart-box-overlay{
    height: 350px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    text-align: center;
    /*line-height: 330px;*/
    margin-top: 120px;
    /*font-size: 20px;*/
    /*background-color: rgba(0,0,0,.5);*/
  }


  .power-box {
    border-color: rgb(138, 138, 138);
    border-style: solid;
    border-radius: 10px;
    border-width: thin;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    /* 100 = viewport width, as 1vw = 1/100th of that
       So if the container is 50% of viewport (as here)
       then factor that into how you want it to size.
       Let's say you like 5vw if it were the whole width,
       then for this container, size it at 2.5vw (5 * .5 [i.e. 50%])
    */
    font-size: 300%;
  }

  .tank-temp {
    font-size: 150%;
    color:white;
    padding:10px;
  }
  .tank-num {
    font-size: 100%;
    color:white;
    padding-top:10px;
  }

  .tank-box {
    border-color: rgb(138, 138, 138);
    border-style: solid;
    border-radius: 10px;
    border-width: thin;
    width: 100px;
    padding: 5px;
    background-image: linear-gradient(red, #007bffc2);
    
  }
  .navbar-color {
    background-color: #eaf1fa; /*#0f62fc;*/
    color:#322e59 !important;
    /*box-shadow: 0px 14px 60px rgba(34,35,58,0.2);*/
    /*padding-left: 0.5rem;
    padding-right: 0.5rem;*/
  }

  .auth-wrapper {
    margin: auto; /* for fixed menu */
    /*margin: -4.5rem 0px 0px 0px;*/
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }

  
  .auth-inner {
    width: 500px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34,35,58,0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 5px ;
    transition: all .3s;
  }

  @media (max-width: 568px){
    .auth-inner {
      width: 100%!important ;
      padding-left: 15px!important ;
      padding-right: 15px!important ;
      display:block!important ; /*override default flex*/
    }
    .auth-wrapper {
      margin-left: 0px!important ;
      margin-right: 0px!important ;
      /*margin: -40px 0px 0px 0px;*/
      
    }
    .container {
      padding-left: 10px!important ;
      padding-right: 10px!important ;

    }
  }

  .auth-wrapper .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }

  .auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }

  .custom-control-label {
    font-weight: 400;
  }

  .forgot-password,
  .forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  } 

  .forgot-password a {
    color: #167bff;
  }

 /* @media (min-width: 576px){
    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 10px!important;
        margin-bottom: 0;
        margin-left: 10px!important;
    }
    .card-deck .card {
        margin-bottom: 10px!important;
    }
  }*/

  .card-stats .card-body {
        padding: 1rem 1.5rem;
    }

  .card-stats .card-inner {
    display: flex;  height: 100%; flex-direction: column;justify-content: space-between;
  }

  .card-stats .card-status-bullet {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }
  .card-translucent {
      background-color: rgba(18, 91, 152, 0.08);
  }

  @media (max-width: 768px){
    .card-deck {
        display:block!important ; /*override default flex*/
    }
  }
  /*@media (min-width: 768px){
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
  }*/
  .dropdown-menu.show {
    z-index:1031;
  } 
  
  .icon-shape {
    padding: 10px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
		font-size: 1.25rem;
	}

  .subtitle-description {
    /*display: inline-flex;*/
    white-space: nowrap;
		font-size: 0.9rem;
	}

  .colflex {
    flex:1 0 auto!important;
  }

  .text-light-muted {
    color: #7a7e81a4 !important;
  }


  .color-solar{color:#f87979!important}
  .color-grid{color:#17a2b8!important}
  .color-temp1{color:blue!important}
  .color-temp2{color:green!important}
  .bg-solar{background:#f87979!important}
  .bg-grid{background:#17a2b8!important}
  .bg-temp1{background:blue!important}
  .bg-temp2{background:green!important}
  .bg-white{background:white!important}
  .bg-gradient-blue{background:linear-gradient(87deg,#5e72e4,#825ee4)!important}
  .bg-gradient-indigo{background:linear-gradient(87deg,#5603ad,#9d03ad)!important}
  .bg-gradient-purple{background:linear-gradient(87deg,#8965e0,#bc65e0)!important}
  .bg-gradient-pink{background:linear-gradient(87deg,#f3a4b5,#f3b4a4)!important}
  .bg-gradient-red{background:linear-gradient(87deg,#f5365c,#f56036)!important}
  .bg-gradient-orange{background:linear-gradient(87deg,#fb6340,#fbb140)!important}
  .bg-gradient-yellow{background:linear-gradient(87deg,#ffd600,#beff00)!important}
  .bg-gradient-green{background:linear-gradient(87deg,#2dce89,#2dcecc)!important}
  .bg-gradient-teal{background:linear-gradient(87deg,#11cdef,#1171ef)!important}
  .bg-gradient-cyan{background:linear-gradient(87deg,#2bffc6,#2be0ff)!important}
  .bg-gradient-gray{background:linear-gradient(87deg,#8898aa,#888aaa)!important}
  .bg-gradient-gray-dark{background:linear-gradient(87deg,#32325d,#44325d)!important}
  .bg-gradient-light{background:linear-gradient(87deg,#ced4da,#cecfda)!important}
  .bg-gradient-lighter{background:linear-gradient(87deg,#e9ecef,#e9eaef)!important}  

  .b-calendar output {
      width: 100% !important;
  }

  .b-calendar .b-calendar-grid {
      width: 100% !important;
  }


</style>
