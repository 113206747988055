<template>
    
    <div class="content">
        <div class="" v-if="showDeviceData">
            <DeviceListSelect 
                :deviceList="deviceList" 
                :selectedDevice="selectedDeviceId" 
                @deviceSelected="onDeviceSelected" 
                :graphProps="query" 
                class="mb-2 mb-md-4"/>
            <!--<b-button @click.prevent="actualList" variant="primary">actual list</b-button>-->
        </div>
            <ActualInfoAll v-if="showDeviceData && showAll" @deviceSelected="onDeviceSelected"/>
            <div v-if="showDeviceData && !showAll">
                <!--<b-card class="mb-2 mb-md-4"
                    :title="$t('Aktuální stav')"
                    :titleClass='text-muted'
                    :show-footer-line="true">
                 -->   
                <ActualInfo />
                <DeviceWarning :actualDeviceData="actualDeviceData" />
                <DeviceError :actualDeviceData="actualDeviceData" />
                    
                <!--</b-card>-->
                <SensorInfo ref="sensorInfo" :sensorData="sensorData" @filterSelected="onFilterSelected"/>
            </div>
            <div v-if="!showDeviceData && isDeviceList" class="d-flex justify-content-center">
                <div class="mt-4" >{{$t("no_devices_in_dashboard")}}&nbsp;<router-link to="/devices">{{$t("here")}}</router-link></div>
            </div>
        
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import axios from 'axios'
import DeviceListSelect from './DeviceListSelect.vue'
import SensorInfo from './SensorInfo.vue'
import ActualInfo from './ActualInfo.vue'
import ActualInfoAll from './ActualInfoAll.vue'
import DeviceError from './DeviceError.vue'
import DeviceWarning from './DeviceWarning.vue'
//import ConsumptionTable from './ConsumptionTable.vue'
//<h3 v-if="!user">You are not logged in!</h3>
//<div class="content">
/*
		3xx & Power	Power
bit	STATE_ERR_KERB	Vse vezmu z MODBUS_GEN_ERR	
0	chyba čidel		0
1	nepřipojená zátěž		MODBUSxS_SYS_NOLOAD
2	chyba izolace tělesa		0
3	přehřátí elektroniky		MODBUSxS_OVERHEATING
4	teplotní pojistka		0
5	chybný odpor tělesa		0
6	chyba ventilátoru		MODBUSxS_SYS_FAN
7	jiný chybový stav		MODBUSxS_OVERVOLTAGE | MODBUSxS_OVERCURRENT | MODBUSxS_SYS_PWR | MODBUSxS_SYS_MEM
*/
export default {
    name: 'Dashboard',
    components: {
        DeviceListSelect,
        SensorInfo,
        ActualInfo,
        ActualInfoAll,
        DeviceError,
        DeviceWarning
        //ConsumptionTable
    },
    data() {
        return {
            //selectedDevice: null,
            sensorData: null
        }
    },
    props: ['query'],
    computed: {
        ...mapGetters(['user', 'deviceList']),
        ...mapGetters(['deviceData', 'filterType', 'filterFrom', 'filterTo', 'selectedDeviceId', 'actualDeviceData']),

        count(){
            var {length = 0} = this.deviceList || {}
            return length 
        },
        showDeviceData(){
            var {length = 0} = this.deviceList || {}
            console.log("showDeviceData", length > 0)
            return length > 0 
        },
        showAll(){
            return this.selectedDeviceId == 'all' 
        },
        isDeviceList(){
            return this.deviceList != null
        }
    },
    methods: {
        actualList(){
           this.$store.dispatch("get_actual_device_data_list", {}) 
           .then((result) => {

                    
                        console.log('get_actual_device_data_list:', result)
                  })
                  .catch(error => {
                     { 
                        console.log(error.response)
                      }
                  });
        },
        onDeviceSelected: function (id) {
            console.log('onDeviceSelected',id)
            this.$store.dispatch("set_selected_device_id", id)
            history.pushState({deviceId:id},'Dashboard','/dashboard/'+id);
            if (id == 'all'){
                this.actualList()
            } else {
                this.getDeviceData(id)
                this.getActualDeviceData(id)
            }
        },
        onFilterSelected: function (payload) {
            console.log(payload)
            this.$store.dispatch("set_selected_filter", payload)
            this.getDeviceData(this.selectedDeviceId)
            this.getActualDeviceData(this.selectedDeviceId)
        },
        loadDeviceDetails(id){
            axios
                  .get('Sensor/DataByDeviceId/'+ id)
                  .then(({ data, status }) => {
                    if (status === 200) {
                        this.$data.selectedDevice = id
                        this.$data.sensorData = data
                        console.log('sensorData:', data)
                      //commit('set_sim_list', data)  
                      //resolve(true);
                    }
                  })
                  .catch(error => {
                      if (error.response.status === 401) {
                            console.log('unauthorized, logging out ...');
                            //this.$router.push('/login')
                        }
                      else { 
                        console.log(error.response)
                      }
                   //reject(error);
                  });
        
        },
        getDeviceData(id){
            if (id == null) return
            var payload = {
                id: id,
                filterType: this.filterType, 
                filterFrom: this.filterFrom,
                filterTo: this.filterTo
            }
            console.log('get_device_data', payload)
            this.$store.dispatch("get_device_data", payload)
            .then(success => {
                console.log('loaded device data', success)
            })
            .catch(error => {
                console.log('failed to load sensor data', error)
                if (error && error.status && error.status != 400 && error.status !== 500 )
                    alert(this.$t('error_load_meas_data') + ' ' + error)
                //this.$router.push('/login')
                //this.error = true;
            })
        },
        getActualDeviceData(id){
            if (id == null) return
            
            this.$store.dispatch("get_actual_device_data", id)
            .then(success => {
                console.log('loaded device actual data', success)
            })
            .catch(error => {
                console.log('failed to load actual device data', error)
                if (error && error.status && error.status != 400 && error.status !== 500 )
                    alert(this.$t('error_load_actual_data') + ' ' + error)
                //this.$router.push('/login')
                //this.error = true;
            })
        },
        getDevices(){
            this.$store.dispatch("get_device_list")
            .then(success => {
                console.log('loaded device data', success)
                if (this.deviceList.length >= 1) {
                    var found = false
                    if (this.selectedDeviceId != null) {
                        if (this.selectedDeviceId == 'all') {
                            found = true
                            history.replaceState({deviceId:this.selectedDeviceId},'Dashboard','/dashboard/'+this.selectedDeviceId);
                            this.actualList()
                        }
                        else
                        for (var i=0; i<this.deviceList.length; i++){
                            var device = this.deviceList[i]
                            if (device && device.sims && device.sims.length>0 && this.selectedDeviceId == device.id) {
                                found = true
                                this.$store.dispatch("set_selected_device_id", device.id)
                                history.replaceState({deviceId:this.selectedDeviceId},'Dashboard','/dashboard/'+this.selectedDeviceId);
                                //this.$store.dispatch("set_selected_device", device)
                                //this.getDeviceData(this.selectedDeviceId)
                                //this.getActualDeviceData(this.selectedDeviceId)

                                break
                            }
                        }
                    }
                    if (!found) {
                        for (i=0; i<this.deviceList.length; i++){
                            // select first possible device from list
                            device = this.deviceList[i]
                            if (device && device.sims && device.sims.length>0){
                                found = true
                                this.$store.dispatch("set_selected_device_id", device.id)
                                history.replaceState({deviceId:this.selectedDeviceId},'Dashboard','/dashboard/'+this.selectedDeviceId);
                                //this.$store.dispatch("set_selected_device", device)
                                //this.getDeviceData(this.selectedDeviceId)
                                //this.getActualDeviceData(this.selectedDeviceId)
                                break
                            }
                        }
                    }
                    if (!found) {
                        for (i=0; i<this.deviceList.length; i++){
                            // select first possible device from list
                            device = this.deviceList[i]
                            if (device && device.sims && device.sims.length>0){
                                found = true
                                this.$store.dispatch("set_selected_device_id", device.id)
                                history.replaceState({deviceId:this.selectedDeviceId},'Dashboard','/dashboard/'+this.selectedDeviceId);
                                //this.$store.dispatch("set_selected_device", device)
                                this.getDeviceData(this.selectedDeviceId)
                                this.getActualDeviceData(this.selectedDeviceId)
                                break
                            } else if (device) {
                                // no sim
                                found = true
                                this.$store.dispatch("set_selected_device_id", device.id)
                                history.replaceState({deviceId:this.selectedDeviceId},'Dashboard','/dashboard/'+this.selectedDeviceId);
                                break
                            }
                        }
                    }
                    
                }

            })
            .catch(error => {
                console.log('failed to load device data', error)
                //this.$router.push('/login')
                //this.error = true;
            })
        }
    },
    async created() {
        /*var selId = this.$route.params.deviceId //this.deviceId; //
        console.log("route dashboard device id", selId, this.selectedDeviceId)
        var qstr= "?s=false"
        if (this.query) {
            //qstr += "?s=false"
            console.log('query', this.query)
        }
        if (selId) {
            this.$store.dispatch("set_selected_device_id", selId)
            history.replaceState({deviceId:this.selectedDeviceId},'Dashboard','/dashboard/'+this.selectedDeviceId + qstr);
            console.log("route dashboard device id", selId, this.selectedDeviceId)
        }

        this.getDevices()*/
    },
    async beforeMount() {
        console.log("---Dashboard before mount")
        var selId = this.$route.params.deviceId //this.deviceId; //
        console.log("route dashboard device id", selId, this.selectedDeviceId)
        var qstr= ""//"?s=false"
        if (this.query) {
            //qstr += "?s=false"
            console.log('query', this.query)
        }
        if (selId) {
            this.$store.dispatch("set_selected_device_id", selId)
            history.replaceState({deviceId:this.selectedDeviceId},'Dashboard','/dashboard/'+this.selectedDeviceId + qstr);
            console.log("route dashboard device id", selId, this.selectedDeviceId)
        }

        this.getDevices()
    }
}
</script>