<template>
    <div @touchend="handleTouchEnd($event)">
        
        <b-card title="" v-if="showActualData" class="mb-2">
            <h5 class="card-title text-uppercase text-muted mb-2">{{ $t('overview') }}</h5>
            <b-row >
                <b-col>
                    <FilterSelect @filterSelected="onFilterSelected"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <ChartLegend :legendData="legendData" :variant="2"/>
                    <!--<div class="d-flex mb-1">
                    <b-button ref="tooltipBtn" id="tooltipBtn" @click="toggleTooltipState" variant="light" size="sm" class="ml-auto"> 
                        <b-iconstack v-if="tooltipEnabled" >
                            <b-icon stacked icon="chat-left" variant="info"  :class="tooltipIconClass"></b-icon>
                        </b-iconstack>
                        <b-iconstack v-if="!tooltipEnabled">
                            <b-icon stacked icon="chat-left" variant="info"  :class="tooltipIconClass"></b-icon>
                            <b-icon stacked icon="x" variant="info" shift-v="2" :class="tooltipIconClass"></b-icon>
                        </b-iconstack>
                    </b-button>
                    </div>-->
                    <div class="chart-box">
                        <MixChart ref="chart1" :height="350" :chartData="chartData" :options="chartOptions"/>
                        <div v-if="isLoading" class="chart-box-overlay">
                            <div  class="text-center" >
                                <b-spinner variant="primary" font-scale="3" label="Loading..."></b-spinner>
                            </div>
                        </div>
                        <div v-if="isError" class="chart-box-overlay">
                            <div  class="text-center ml-5 mr-5 pl-5 pr-5">
                                <div class="d-block" style="font-size: 20px;" ><b-icon icon="exclamation-triangle-fill" scale="2" variant="danger"></b-icon></div>
                                <div class="d-block mt-1" >{{errorMessage}}</div>
                            </div>
                        </div>
                        <div v-if="!isError&&!isLoading&&isEmpty" class="chart-box-overlay">
                            <div  class="text-center ml-5 mr-5 pl-5 pr-5">
                                <div class="d-block" style="font-size: 20px;" ><b-icon icon="exclamation-triangle-fill" scale="2" variant="secondary"></b-icon></div>
                                <div class="d-block mt-1" >{{$t('no_data_available_interval')}}</div>
                            </div>
                        </div>
                        <b-button ref="tooltipBtn" id="tooltipBtn" @click="toggleTooltipState" variant="link" size="sm" class="ml-auto" :title="$t('chart_tooltip')"> 
                            <b-iconstack v-if="tooltipEnabled" >
                                <b-icon stacked icon="chat-left-text" variant="info"  :class="tooltipIconClass" ></b-icon>
                            </b-iconstack>
                            <b-iconstack v-if="!tooltipEnabled">
                                <b-icon stacked icon="chat-left" variant="info"  :class="tooltipIconClass" ></b-icon>
                                <b-icon stacked icon="x" variant="info" shift-v="2" :class="tooltipIconClass"></b-icon>
                            </b-iconstack>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row >
                <b-col>
            <ConsumptionTable />
            </b-col>
            </b-row>
        </b-card>
        

        <b-row>
            <b-col></b-col>
            <b-col>
            </b-col>
            <b-col></b-col>
        </b-row>
    </div>
    
</template>

<script>
import {mapGetters} from 'vuex'

import ConsumptionTable from './ConsumptionTable.vue'

import MixChart from '../Common/MixChart.js'
import FilterSelect from './FilterSelect.vue'
import ChartLegend from './ChartLegend.vue'

import 'chartjs-adapter-date-fns';
import {differenceInDays, parseISO, startOfMonth, subDays} from 'date-fns'
/*import { 
    startOfMonth, 
    lastDayOfMonth, 
    subMonths, 
    differenceInDays, 
    //differenceInMonths, 
    formatISO, 
    addMonths, 
    addDays, 
    //addYears 
    } from 'date-fns'*/
import consts from '../../consts.js'

/*const LegendClickHandler = function(e, legendItem, legend) {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;
    if (ci.isDatasetVisible(index)) {
        ci.hide(index);
        legendItem.hidden = true;
    } else {
        ci.show(index);
        legendItem.hidden = false;
    }
}*/
var powerUnitDivider = 1
var lastDeviceType = consts.DEVICE_TYPE_STANDARD
export default {
    name: 'SensorInfo',
    components: {
        MixChart,
        FilterSelect,
        ChartLegend,
        ConsumptionTable
    },
    data() {
        return {
        
        }
    },
    props: ['sensorData', 'loading', 'graphProps'],
    computed: {
        ...mapGetters(['user', 'lang', 'legendHide', 'colors', 'showChartTooltip']),
        ...mapGetters(['actualDeviceData','deviceData', 'filterType', 'filterFrom', 'filterTo', 'selectedDeviceId']),
        showDeviceData(){
            return this.deviceData != null
        },
        showActualData(){
            //return this.actualDeviceData != null
            var result = (this.actualDeviceData != null) && (!this.actualDeviceData.loading) && (!this.actualDeviceData.loadingError)
            return result
        },
        isLoading(){
            var {loading = false} = this.deviceData || {}
            return loading
        },
        isError(){
            var {loadingError = null} = this.deviceData || {}
            return loadingError
        },
        isEmpty(){
            try {
                var {intervalEndTime = []} = this.deviceData || {}
                console.log('isEmpty', intervalEndTime, intervalEndTime.length == 0)
                return intervalEndTime.length == 0                
            } catch (error) {
                return true
            }
        },
        errorMessage(){
            var {loadingError = null} = this.deviceData || {}
            return loadingError
        },
        legendData(){
            if (this.chartData && this.chartData.datasets) return this.chartData.datasets
            else return []
        },
        
        chartOptions(){
            var self = this
            console.log('graph props', this.graphProps)
            var tickSettings = {}
            var limits = this.getLimitsByFilter(this.filterType)
            var {start, end, timeUnits} = limits
            tickSettings.min = start
            tickSettings.max = end


            console.log('chartOptions timeunits',timeUnits)
            var timeSettings = {
                            //parser: 'YYYY-MM-DDTHH:mm:ss',
                            //parser: "yyyy-MM-dd'T'HH:mm:ss'Z'",
                            unit: timeUnits,
                            //format: "yyyy-MM-dd'T'HH:mm:ss",
                            tooltipFormat: this.isBarGraph()?'P':'Pp',
                            displayFormats: {
                                'minute': 'p',//(this.lang == 'en') ?  'hh:mm aaa' : 'HH:mm',
                                'hour': 'Pp'//(this.lang == 'en') ?  'MM/dd, hh:mm aaa' : 'dd.MM. HH:mm'
                            }
            }
            if (timeUnits == 'minute') {
                timeSettings.stepSize = 30  
            }

            
            var options = {
                locale: this.lang,
                title: {
                    display: false,
                    text: "Chart filter " + this.filterType
                },
                animation: {
                    duration: 100, // general animation time
                    easing:	'easeOutCirc'
                },
                transitions: {
                    active: { animation: {duration: 0}},// disables the animation for 'active' mode
                    hide: { animation: {duration: 0}},
                    reset: { animation: {duration: 0}},
                    resize: { animation: {duration: 0}},
                    show: { animation: {duration: 0}},
                },
                interaction: this.showChartTooltip?{
                    mode: 'index',
                    intersect: false
                }:{
                    mode: 'point',
                    intersect: false
                },
                //options.transitions.active.animation.duration = 0; // disables the animation for 'active' mode
                
                scales: {
                    power: 
                        {
                            type: 'linear',
                            beginAtZero: true,
                            ticks: {
                                beginAtZero: true,
                                callback: function(value, index, values) {
                                    //console.log(value, index, values)
                                    var result = value
                                    if (values){
                                        if (powerUnitDivider == 1000) {
                                            value = value/1000
                                            result = new Intl.NumberFormat(consts.locales[self.lang]?.code, { style: 'decimal', minimumIntegerDigits:1, minimumFractionDigits:2, maximumFractionDigits:2 }).format(value)
                                        }
                                    }
                                    return result
                                }
                            },
                            //id:'power',
                            position: 'left',
                            // scaleLabel: {
                            //     display: true,
                            //     labelString: this.isBarGraph()? this.$t('energy')+ ' [kWh]':this.$t('power')+ ' [W]'
                            // }
                            grid: { display: false },
                            title: {
                                display: true,
                                text: ()=>{
                                    return (this.isBarGraph()? this.$t('energy'):this.$t('power')) + ' [' + this.powerUnit() + ']'
                                },
                                // color: '#911',
                                // font: {
                                //     family: 'Comic Sans MS',
                                //     size: 20,
                                //     style: 'bold',
                                //     lineHeight: 1.2,
                                // },
                                // padding: {top: 20, left: 0, right: 0, bottom: 0}
                                },
                            suggestedMin: 0,
                            suggestedMax: 100,
                            yAxisID:'power',
                        },
                    temp:    {
                            type: 'linear',
                            beginAtZero: true,
                            ticks: {
                                beginAtZero: true
                            },
                            position:'right',
                            grid: { display: false },
                            title: {
                                display: true,
                                text: (this.isBarGraph()? this.$t('average_temperature'): this.$t('temperature')) + ' [°C]',
                                },
                            suggestedMin: 0,
                            suggestedMax: 50

                        }
                    ,
                    x: {
                        offset: true,
                        adapters: {
                            date: {
                                locale: consts.locales[this.lang]
                            }
                        },
                        type:       "time",
                        time:       timeSettings,
                        title: {
                                display: true,
                                text: this.$t('time'),
                        },
                        ticks: tickSettings,
                    },
                
                },
                plugins: {
                    // htmlLegend: {
                    //     // ID of the container to put the legend in
                    //     containerID: 'legend-container',
                    // },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: this.showChartTooltip,
                        callbacks: {
                            /*title: function(context) {
                                let title = '';
                                console.log('title cb context', context)
                                if (context.parsed.x !== null) {
                                    title += context.parsed.x
                                }
                                return title;
                                //return self.datasetTooltipCbPower(context)
                            }*/
                        }
                    },
                    /*zoom: {
                        pan: {
                        // pan options and/or events
                            enabled: true,
                            scaleMode: 'xy'
                        },
                        limits: {
                        // axis limits
                        },
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'xy',
                        }
                    },*/
                    /*title: {
                        display: true,
                        position: 'bottom',
                        text: (ctx) => 'Zoom: ' + this.zoomStatus(ctx.chart) + ', Pan: ' + this.panStatus()
                    }*/
                    /*legend: {
                        onClick: function(e, legendItem, legend) {
                            //console.log('onClickLegend', e,legendItem, legend)
                            const index = legendItem.datasetIndex;
                            const ci = legend.chart;
                            if (ci.isDatasetVisible(index)) {
                                ci.hide(index);
                                localStorage.setItem('datasetHide'+index, true)
                                legendItem.hidden = true;
                            } else {
                                ci.show(index);
                                legendItem.hidden = false;
                                localStorage.setItem('datasetHide'+index, false)
                            }
                        }
                    },*/
                    //htmlLegendPlugin
                },
                responsive: true,
                maintainAspectRatio: false,
                onResize: (context) => {
                    console.log("--- onresize fired", context)
                    let tooltipBtn = document.getElementById('tooltipBtn')
                    if (tooltipBtn) {
                        console.log("--- tooltipBtn", tooltipBtn, context)
                        const xPos = context.width - 85
                        const yPos = (context.height - 5) * -1
                        tooltipBtn.style.position = 'relative'
                        tooltipBtn.style.left = xPos + 'px'
                        tooltipBtn.style.top = yPos + 'px'
                    }
                }

            }
            if (tickSettings.min) {
                options.scales.x.suggestedMin = tickSettings.min
                options.scales.x.min = tickSettings.min
            }
            if (tickSettings.max)
                options.scales.x.suggestedMax = tickSettings.max

            
            return options
        },
        chartData(){
                var deviceType = lastDeviceType//consts.DEVICE_TYPE_STANDARD
                console.log("--- chartData fired", this.deviceData)
                //console.log("legendHide", this.legendHide)
                var self = this
                var isBarGraph = this.isBarGraph() // calc only once
                var dataSolar = []
                var dataGrid = []
                //var dataSolar1 = []
                //var dataSolar2 = []
                //var dataSolar3 = []
                var dataTank1 = []
                var dataTank2 = []
                var dataTank1TempLimitAC = [] // temp limit for tank 1 for heating from AC
                var dataTank1TempLimitSolar = [] // temp limit for tank 1 for heating from solar
                var dataTank2TempLimitSolar = [] // temp limit for tank 2 for heating from AC 
                var intervalData = []
                if (this.deviceData) {
                    if (this.deviceData.powerFromSolar) {
                        deviceType = consts.DEVICE_TYPE_STANDARD
                        dataSolar = this.deviceData.powerFromSolar
                        dataGrid = this.deviceData.powerFromGrid
                        dataTank1 = this.deviceData.tempTank1
                        dataTank2 = this.deviceData.tempTank2
                        dataTank1TempLimitAC = this.deviceData.tempSetGrid 
                        dataTank1TempLimitSolar = this.deviceData.tempSetSolar
                        dataTank2TempLimitSolar = this.deviceData.tempSetSolar2 // TODO zmenit az budou posilana spravna data, zatim se neposilaji
                    } else if (this.deviceData.powerFromSolarTotal) {
                        deviceType = consts.DEVICE_TYPE_POWER
                        dataSolar = this.deviceData.powerFromSolarTotal
                        //dataSolar1 = this.deviceData.powerFromSolar1
                        //dataSolar2 = this.deviceData.powerFromSolar2
                        //dataSolar3 = this.deviceData.powerFromSolar3
                        dataTank1 = this.deviceData.temp
                        dataTank1TempLimitSolar = this.deviceData.tempSetSolar
                    }
                    intervalData = this.deviceData.intervalEndTime
                }
                lastDeviceType = deviceType // store for next update
                var opacity = 90
                var lineTension = 0.4
                if (deviceType == consts.DEVICE_TYPE_STANDARD) {
                    var datasetSolar = {
                            label: this.$t('solar'),
                            backgroundColor: this.convertHexToRGBA(this.colors['solar'], opacity),//'#f87979',
                            //borderColor: this.colors['solar'],
                            color: this.colors['solar'],
                            data: dataSolar,
                            order:5,
                            type: (isBarGraph)?'bar':'line',
                            lineTension: lineTension,
                            categoryPercentage: 1.0,
                            barPercentage: 1.0,
                            minBarLength: 2,
                            pointRadius: 0,
                            yAxisID:'power',
                            fill: true,
                            legendIndex: 0,
                            hidden: this.legendHide[0],//this.legendHidden0, //localStorage.getItem('datasetHide'+0) == "true",
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return self.datasetTooltipCbPower(context)
                                    }
                                }
                            },
                            name:"dataSolar",
                        }
                    var datasetGrid = {
                            label: this.$t('grid'),
                            backgroundColor: this.convertHexToRGBA(this.colors['grid'], opacity),//'#17a2b8',
                            data: dataGrid,
                            order:6,
                            categoryPercentage: 1.0,
                            barPercentage: 1.0,
                            minBarLength: 2,
                            type: (isBarGraph)?'bar':'line',
                            lineTension: lineTension,
                            pointRadius: 0,
                            yAxisID:'power',
                            fill: true,
                            legendIndex: 3,
                            hidden: this.legendHide[3],//this.legendHidden3, //localStorage.getItem('datasetHide'+3) == "true",
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return self.datasetTooltipCbPower(context)
                                    }
                                }
                            }
                        }
                    var datasetTank1Temp = {
                            label: this.$t('tank_1_temperature'),
                            data: dataTank1,
                            backgroundColor: this.colors['tank_1_temperature'],//'blue',
                            borderColor: this.colors['tank_1_temperature'],
                            fill:false,
                            // Changes this dataset to become a line
                            type: 'line',
                            //lineTension: lineTension,
                            pointRadius: 0,
                            order:0,
                            yAxisID:'temp',
                            legendIndex: 1,
                            hidden: this.legendHide[1],//this.legendHidden1, //localStorage.getItem('datasetHide'+1) == "true",
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return self.datasetTooltipCbTemp(context)
                                    }
                                }
                            }
                        }    
                    var datasetTank2Temp = {
                            label: this.$t('tank_2_temperature'),
                            data: dataTank2,
                            backgroundColor: this.colors['tank_2_temperature'],//'green',
                            borderColor: this.colors['tank_2_temperature'],
                            fill:false,
                            // Changes this dataset to become a line
                            type: 'line',
                            //lineTension: lineTension,
                            pointRadius: 0,
                            order:1,
                            yAxisID:'temp',
                            legendIndex: 2,
                            hidden: this.legendHide[2], //this.legendHidden2, //localStorage.getItem('datasetHide'+ 2) == "true",
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return self.datasetTooltipCbTemp(context)
                                    }
                                }
                            }
                        } 
                    var datasetTank1LimitGrid = {
                            label: this.$t('tank_1_temperature_limit_ac'),
                            data: dataTank1TempLimitAC,
                            backgroundColor: this.colors['tank_1_temperature_limit_ac'],//'#137e8f',
                            borderColor: this.colors['tank_1_temperature_limit_ac'],
                            borderDash: [5, 5],
                            borderWidth: 1,
                            fill:false,
                            // Changes this dataset to become a line
                            type: 'line',
                            //lineTension: lineTension,
                            pointRadius: 0,
                            order:2,
                            yAxisID:'temp',
                            legendIndex: 'Tank1LimitAc',
                            hidden: this.legendHide['Tank1LimitAc'],
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return self.datasetTooltipCbTemp(context)
                                    }
                                }
                            }
                        }
                    var datasetTank1LimitSolar = {
                            label: this.$t('tank_1_temperature_limit_solar'),
                            data: dataTank1TempLimitSolar,
                            backgroundColor: this.colors['tank_1_temperature_limit_solar'], //'#dd5b5b',//'#6a6aff',
                            borderColor: this.colors['tank_1_temperature_limit_solar'],
                            borderDash: [5, 5],
                            borderWidth: 1,
                            fill:false,
                            // Changes this dataset to become a line
                            type: 'line',
                            //lineTension: lineTension,
                            pointRadius: 0,
                            order:3,
                            yAxisID:'temp',
                            legendIndex: 'Tank1LimitSolar',
                            hidden: this.legendHide['Tank1LimitSolar'],
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return self.datasetTooltipCbTemp(context)
                                    }
                                }
                            }
                        } 
                    var datasetTank2LimitSolar = {
                            label: this.$t('tank_2_temperature_limit_solar'),
                            data: dataTank2TempLimitSolar,
                            backgroundColor: this.colors['tank_2_temperature_limit_solar'],//'#02af02',
                            borderColor: this.colors['tank_2_temperature_limit_solar'],//'#02af02',
                            borderDash: [5, 5],
                            borderWidth: 1,
                            fill:false,
                            // Changes this dataset to become a line
                            type: 'line',
                            pointRadius: 0,
                            order:4,
                            yAxisID:'temp',
                            legendIndex: 'Tank2LimitSolar',
                            hidden: this.legendHide['Tank2LimitSolar'],
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return self.datasetTooltipCbTemp(context)
                                    }
                                }
                            }
                        }   
                        
                    // base dataset    
                    var datasets = [datasetSolar, datasetGrid, datasetTank1Temp, datasetTank2Temp]
                    if (!isBarGraph) {
                        // rozsirit dataset o limity pokud je carovy graf 
                        datasets = [...datasets, datasetTank1LimitSolar, datasetTank2LimitSolar, datasetTank1LimitGrid]                
                    }
                    var chartdata =  {
                        datasets: datasets,
                        labels: intervalData
                    }
                    var maxPow = Math.max(...dataSolar, ...dataGrid)
                    if (maxPow > 1000) {
                        powerUnitDivider = 1000
                    } else {
                        powerUnitDivider = 1
                    }
                } else {
                    datasets = [
                        {
                            label: this.$t('solar'),
                            backgroundColor: this.colors['solar'],//'#f87979',
                            color: this.colors['solar'],//'#f87979',
                            data: dataSolar,
                            order:2,
                            type: (this.isBarGraph())?'bar':'line',
                            lineTension: lineTension,
                            categoryPercentage: 1.0,
                            barPercentage: 1.0,
                            minBarLength: 2,
                            pointRadius: 0,
                            yAxisID:'power',
                            fill: true,
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return self.datasetTooltipCbPower(context)
                                    }
                                }
                            },
                            legendIndex: 0,
                            hidden: this.legendHide[0],

                        }, 
                        {
                            label: this.$t('tank_temperature'),
                            data: dataTank1,
                            backgroundColor: this.colors['tank_1_temperature'],//'blue',
                            borderColor: this.colors['tank_1_temperature'],//'blue',
                            fill:false,
                            // Changes this dataset to become a line
                            type: 'line',
                            //lineTension: lineTension,
                            pointRadius: 0,
                            order:0,
                            yAxisID:'temp',
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return self.datasetTooltipCbTemp(context)
                                    }
                                }
                            },
                            legendIndex: 1,
                            hidden: this.legendHide[1],//this.legendHidden1//localStorage.getItem('datasetHide'+1) == "true"

                        }]

                    if (!isBarGraph) {
                        // rozsirit dataset o limity pokud je carovy graf 
                        datasets = [...datasets, 
                            {
                                label: this.$t('tank_temperature_limit_solar'),
                                data: dataTank1TempLimitSolar,
                                backgroundColor: this.colors['tank_1_temperature_limit_solar'],//'#dd5b5b',//'#6a6aff',
                                borderColor: this.colors['tank_1_temperature_limit_solar'],//'#dd5b5b',
                                borderDash: [5, 5],
                                borderWidth: 1,
                                fill:false,
                                // Changes this dataset to become a line
                                type: 'line',
                                //lineTension: lineTension,
                                pointRadius: 0,
                                order:3,
                                yAxisID:'temp',
                                legendIndex: 'Tank1LimitSolar',
                                hidden: this.legendHide['Tank1LimitSolar'],
                                tooltip: {
                                    callbacks: {
                                        label: function(context) {
                                            return self.datasetTooltipCbTemp(context)
                                        }
                                    }
                                }
                            }]                
                    } 
                    chartdata =  {
                        datasets: datasets,
                        labels: intervalData
                    } 
                    let maxPow = Math.max(...dataSolar) 
                    if (maxPow > 1000) {
                        powerUnitDivider = 1000
                    } else {
                        powerUnitDivider = 1
                    }  
                }
                console.log('chartdata', chartdata)
                return chartdata
        },
        tooltipIconClass() {
            return this.showChartTooltip ? 'text-dark' : 'text-muted';
        },
        tooltipEnabled() {
            return this.showChartTooltip;
        },
    },
    methods: {
        //panStatus() {return this.chartOptions.plugins.zoom.zoom.pan.enabled ? 'enabled' : 'disabled';},
        //zoomStatus(chart) {return (this.chartOptions.plugins.zoom.zoom.wheel.enabled ? 'enabled' : 'disabled') + ' (' + chart.getZoomLevel() + 'x)'},
        toggleTooltipState(){
            this.$store.dispatch("set_show_chart_tooltip", {state: !this.showChartTooltip})
        },
        handleTouchEnd(event) {
            console.log('triggered event handleTouchEnd', event)
            if (event.target && event.target.tagName.toLowerCase() !== "canvas") {
                console.log('triggered event handleTouchEnd to not canvas', event, this.$refs)
                this.$refs.chart1.$el.dispatchEvent(new Event('mouseout'));
            }
        },
        onFilterSelected(payload) {
                console.log('selected filterType value SensorInfo', payload)
                this.$emit('filterSelected', payload)
        },
        powerUnit(){
            var unit = (powerUnitDivider == 1000)? 'k':''
            unit += 'W'
            unit += this.isBarGraph()? 'h':''
            return unit
        },
        isBarGraph() {
            var result = false
            // dnes, vcera => line, ostatni => bar graph
            // vlastni - pod 3 dny => line, ostatni => bar  
            if (this.filterType > 1) {
                result = true
            }
            if (this.filterType == 4) {
                // custom type
                if (this.deviceData != null) {
                    var startTime = parseISO(this.filterFrom)
                    var endTime = parseISO(this.filterTo)
                    var diff = differenceInDays(endTime, startTime)
                    //console.log('Difference in days: ', diff, endTime, startTime)
                    if (diff<3) {
                        // vlastni - pod 3 dny => line
                        result = false
                    }
                }
            }
            return result
        },
        datasetTooltipCbPower(context){
            let label = context.dataset.label || '';
            if (label) {
                label += ': ';
            }
            if (context.parsed.y !== null) {
                if (context.parsed.y > 1000){
                    var value = context.parsed.y / 1000
                    //label += value.toFixed(2) + ' kW'
                    label += new Intl.NumberFormat(consts.locales[this.lang]?.code, { style: 'decimal', minimumIntegerDigits:1, minimumFractionDigits:2, maximumFractionDigits:2 }).format(value) + ' kW';
                }
                else{
                    label += //context.parsed.y.toFixed(0) + ' W'
                    new Intl.NumberFormat(consts.locales[this.lang], { style: 'decimal', minimumIntegerDigits:1, minimumFractionDigits:0, maximumFractionDigits:0 }).format(context.parsed.y) + ' W';
                    //new Intl.NumberFormat(locales[this.lang], { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                }
                if (context.dataset.type == 'bar')
                    label += 'h'
            }
            return label;
        },
        datasetTooltipCbTemp(context){
            let label = context.dataset.label || '';

            if (label) {
                label += ': ';
            }
            if (context.parsed.y !== null) {
                label += context.parsed.y.toFixed(0) + ' °C'
            }
            return label;
        },
        getLimitsByFilter(filter){
            var timeUnits = 'minute'
            if (this.isBarGraph()) {
                timeUnits = 'day'
            }
            if (filter == 0) {// today
                var start = new Date();
                start.setHours(0,0,0,0);

                var end = new Date();
                end.setHours(23,59,59,999);
            }
            if (filter == 1) {// yesterday
                var today = new Date();
                //start.setDate(start.getDate()- 1);
                start = subDays(today,1)
                start.setHours(0,0,0,0);
                console.log("Yesterday start", start)

                //end = new Date();
                //end.setDate(start.getDate()- 1);
                end = subDays(today,1)
                end.setHours(23,59,59,999);
                console.log("Yesterday end", end)
            }
            if (filter == 2) {// last 7 
                start = new Date();
                start = subDays(start,6)
                //start.setDate(start.getDate()- 6);
                start.setHours(0,0,0,0);
                console.log("Last 7 start", start)

                end = new Date(); // today
                end.setHours(0,0,0,0);
                console.log("Last 7 end", end)
            }
            if (filter == 3) {// this month 
                start = new Date();
                start = startOfMonth(start)
                console.log("This month start date", start)

                end = new Date(); // today
                end.setHours(0,0,0,0);
                console.log("This month end date", end)
            }
            if (filter == 4) {// custom
                start = new Date(this.filterFrom);
                start.setHours(0,0,0,0);
                console.log("Custom start date", start)

                end = new Date(this.filterTo);
                end.setHours(0,0,0,0);
                console.log("Custom end date", end)
                var diffInDays = (end.getTime() - start.getTime()) / (1000 * 3600 * 24)
                //console.log("filter diffDays",diffInDays)
                if (diffInDays>=90) {
                    timeUnits = 'month'

                } else {
                    //end.setHours(23,59,59,999);
                    if ((diffInDays >= 1) && (diffInDays < 3)) {
                        timeUnits = 'hour'
                        end.setHours(23,59,59,999);
                    } else if (diffInDays < 1) {
                        timeUnits = 'minute'
                        end.setHours(23,59,59,999);
                    }
                }
            }


            return {
                start: start,
                end: end,
                timeUnits: timeUnits
            }
        },
        convertHexToRGBA (hexCode, opacity = 1) {  
            let hex = hexCode.replace('#', '');
            
            if (hex.length === 3) {
                hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
            }    
            
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);
            
            /* Backward compatibility for whole number based opacity values. */
            if (opacity > 1 && opacity <= 100) {
                opacity = opacity / 100;   
            }

            return `rgba(${r},${g},${b},${opacity})`;
        }


        /*onChange(event) {
            console.log(event)
            //console.log(event.target.value)
            this.$emit('deviceSelected',event)
        }*/
    },
    watch: {
        /*legendHidden0: function() {
            this.chartData.update()
        },
        legendHidden: {
            handler(){
                //this.checkNameValidity()
            },
            deep: true
        }*/
    },
    async created() {
            /*var result = []
            for (var i=0; i<4; i++){
                result.push(localStorage.getItem('datasetHide'+i) == "true")
            }
            this.legendHidden = result*/

        //this.getDeviceData()
    }    
}
</script>

