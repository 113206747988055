<template>
    <form @submit.prevent="handleSubmit">
        <Error v-if="error" :error="error"/>
        <h3>{{ $t("signup") }}</h3>
        
        <b-form-group
                :label="$t('email')"
                label-for="email-input"
                :invalid-feedback="$t(emailFeedback)"
                :state="emailState"
                >
                <b-form-input type="email" class="form-control" v-model="email" :placeholder="$t('user_email')" required/>
        </b-form-group>

        <b-form-group
                :label="$t('password')"
                label-for="password-input"
                :invalid-feedback="$t(passwordFeedback)"
                :state="passwordState"
                >
                <b-form-input type="password" class="form-control" v-model="password" :placeholder="$t('password')" required/>
        </b-form-group>

        <b-form-group
                :label="$t('confirm_password')"
                label-for="passwordagain-input"
                :invalid-feedback="$t(passwordagainFeedback)"
                :state="passwordagainState"
                >
                <b-form-input type="password" class="form-control" v-model="passwordagain" :placeholder="$t('confirm_password')" />
        </b-form-group>

        <div class="form-group">
            <label>{{ $t("select_timezone") }}</label>
        <b-form-select id="timeZone" name="timeZones" v-model="selectedTimeZone" @change="onChange($event)">
            <b-form-select-option :value="null" disabled>{{$t('select_timezone')}}</b-form-select-option>
            <b-form-select-option :value="timeZone.id" v-for="timeZone in timeZones" :key="timeZone.id">{{timeZone.displayName}} ({{timeZone.id}})</b-form-select-option>
        </b-form-select>
        </div>

        <button class="btn btn-primary btn-block">{{ $t("signup") }}</button>
        <div class="d-flex justify-content-between">
            <div class="d-inline-flex"> 
                <p class="forgot-password text-right">
                    <router-link to="login">{{ $t("login") }}</router-link>
                </p>
            </div>
            <div class="d-inline-flex">
                <p class="forgot-password">
                    <router-link to="forgot">{{ $t("forgot_password") }}</router-link>
                </p>
            </div>
        </div>
    </form>    
</template>
<script>
//        <Message v-if="message" :message="message"/>
//<div class="form-group">
//            <label>{{ $t("email") }}</label>
//            <input type="email" class="form-control" v-model="email" :placeholder="$t('email')"/>
//        </div>
//<div class="form-group">
//            <label>{{ $t("password") }}</label>
//            <input type="password" class="form-control" v-model="password" :placeholder="$t('password')"/>
//        </div>
//        <div class="form-group">
//            <label>{{ $t("confirm_password") }}</label>
//            <input type="password" class="form-control" v-model="passwordagain" :placeholder="$t('confirm_password')"/>
//        </div>
//import axios from 'axios'
import Error from './Error'
//import Message from './Message'
import {mapGetters} from 'vuex'

export default {
    name: 'Register',
    components: {
        Error,
        //Message
    },
    data() {
        return {
            email: '',
            password: '',
            passwordagain: '',
            error: '',
            selectedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            emailState: null,
            emailFeedback: null,
            passwordState: null,
            passwordagainState: null,
            passwordFeedback: '',
            passwordagainFeedback: ''
        }
    },
    watch: {
        email: function() {
            this.checkEmailValidity()
        },
        password: function() {
            this.checkPasswordValidity()
        },
        passwordagain: function() {
            this.checkPasswordAgainValidity()
        },
        
    },
    methods: {
        checkEmailValidity() {
            var valid = true
            this.email = this.email.trim()
            var regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
            valid = regex.test(this.email);
            if (!valid)
                this.emailFeedback = 'email_bad_format' //this.$t('email_bad_format')
            this.emailState = valid
            return valid
        },
        checkPasswordValidity() {
            var valid = true
            this.password = this.password.trim()
            var pwdRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; //"^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
            valid = pwdRegex.test(this.password);
            if (!valid)
                this.passwordFeedback = 'password_bad_format' //this.$t('password_bad_format')
            this.passwordState = valid
            return valid
        },
        checkPasswordAgainValidity() {
            this.passwordagain = this.passwordagain.trim()
            this.passwordagainState = this.password == this.passwordagain
            if (this.password != this.passwordagain) {
                this.passwordagainFeedback = 'password_not_same'//this.$t('password_not_same')
            } else this.passwordagainFeedback = null
            console.log('Password again state', this.passwordagainState)
            return this.passwordagainState
        },
        checkFormValidity() {
            console.log('Validate start')
            var valid = this.checkEmailValidity()
            valid &= this.checkPasswordAgainValidity() 
            valid &= this.checkPasswordValidity()
            return valid
            // this.passwordState = this.password == this.passwordagain
            // this.passwordagainState = this.passwordState
            // if (this.password != this.passwordagain) {
            //     this.passwordFeedback = this.$t('password_not_same')
            // } else this.passwordFeedback = null
            // return this.passwordState
        },
        parseFormErrors(errors){
            console.log('parse errors', errors)
            this.emailState = errors.Email == true
            if (typeof(errors.Email) == 'object')
                this.emailFeedback = errors.Email[0]
            this.passwordState = errors.Password == true
            if (typeof(errors.Password) == 'object')
                this.passwordFeedback = errors.Password[0]
        },
        async handleSubmit(){
            try {
                if (!this.checkFormValidity()) {
                    return false
                }
                const data = {
                    email: this.email,
                    password: this.password,
                    passwordagain: this.passwordagain,
                    timezone: this.selectedTimeZone
                }
                this.$store.dispatch("register", data)
                .then(success => {
                    console.log('register response', success)
                    console.log('submitted user data', data)
                    this.$router.push('/registerafter') 
                })
                .catch(error => {
                    console.log('failed to register', error)
                    if (error && error.response && error.response.data) {
                        if (typeof(error.response.data)== 'string') {
                            // user exists
                            if (error.response.data.includes("už existuje"))
                                this.error = this.$t("user_already_exists")
                            else
                                this.error = error.response.data
                        } else {
                            // some of parameter wrong
                            if (error.response.data.errors) {
                                this.parseFormErrors(error.response.data.errors)
                            } else {
                                this.error = error.response.data
                            }
                        }
                    } else {
                        this.error = error
                    }
                })
            } catch (e){
                this.error = this.$t('error_occured')
            }
        },
        getTimeZones(){
            console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
            this.$store.dispatch("get_timezones")
            .then(success => {
                console.log('loaded timezones', success)
            })
            .catch(error => {
                console.log('failed to load timezones', error)
            })
        },
        onChange(/*event*/) {
            //console.log(event)
            //console.log('selected device event', event.target.value)
            //this.$emit('deviceSelected',event)
        }
    },
    computed: {
        ...mapGetters(['timeZones']),
        ...mapGetters(['message']),
    },
    async created() {
        this.getTimeZones()
    }
}
</script>