<template>
    <li @click.prevent="labelClicked(index, dataset.label)" class="d-flex justify-content-center align-items-center">
        <label v-if="variant==1" class="label-checkbox" :class="lblClass" :style="lblStyle">
            <input type="checkbox" v-model="legendHide[index]" :value=false class="check-legend" />
            {{ dataset.label }}
        </label>  
        <div v-else class="d-flex justify-content-center align-items-center mb-1">
            <div class="label-checkbox-short" :class="lblClass" :style="lblStyle">
                <!--<input type="checkbox" v-model="legendHide[index]" :value=false class="check-legend" />-->
                
            </div>  
            <div class="label-text-short">{{ dataset.label }}
            </div>
        </div>
    </li>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'ChartLegendItem',
    components: {
    },
    data() {
        return {
        }
    },
    props: ['dataset', 'index', 'color', 'variant'],
    computed: {
        ...mapGetters(['user', 'lang', 'legendHide']),
        ...mapGetters(['actualDeviceData','deviceData', 'filterType', 'filterFrom', 'filterTo', 'selectedDeviceId']),
        lblClass(){
            return {
                'checked': !this.legendHide[this.index], 
                'unchecked': this.legendHide[this.index]
                }
        },
        lblStyle(){
            return {
                'background':!this.legendHide[this.index]?this.dataset.backgroundColor:'white',
                'background-color':!this.legendHide[this.index]?this.dataset.backgroundColor:'white',
                'color':this.legendHide[this.index]?this.dataset.backgroundColor:'white',
                'border-color':this.dataset.backgroundColor //this.variant==1?this.dataset.backgroundColor:'lightgray'//"#c2c2c4"
                }
        }
    },
    methods: {
        labelClicked(index,name){
            console.log('labelClicked', index,name)
            this.$store.dispatch("set_legend_hide", {index:index, state: !this.legendHide[index]})
        },
    }
}
</script>

<style scoped>
ul {
  list-style: none; 
  padding: 0;
  margin: 0;
}

li {
  padding-left: 1rem;
  font-size: 0.75rem;
  font-family: sans-serif;  

}

.label-checkbox-short {
  border: 1px solid #c2c2c4;
  box-sizing: border-box;
  border-radius: 1px;
  padding: auto auto;
  text-align: center;
  display: inline-block;
  width: 1.5rem;
  height: 1.1rem;
  color: #4273DE;
}
.label-text-short {
  margin-left: 0.3rem;
  border: 0px solid #4273DE;
  box-sizing: border-box;
  border-radius: 1px;
  padding: auto 0.5rem;
  text-align: center;
  display: inline-block;
  height: 1rem;
  white-space: nowrap;
}

.label-checkbox {
  /*margin-right: 0.87rem;
  margin-left: auto;*/
  border: 1px solid #4273DE;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0.2rem 0.4rem;
  text-align: center;
  display: inline-block;
  /*font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;*/
  color: #4273DE;
}

.check-legend {
  visibility: hidden;
  position: absolute;
  right: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.checked {
  background: #4273DE;
  color: #fff;
}

.checked::before {
  content: "✔";
}

.unchecked {
  background: #4273DE;
  color: #fff;
}
.unchecked::before {
  content: " ";
}


/*li::before {
  content: "•"; 
  padding-right: 0.5rem;
  color: rgb(143, 143, 145); 
  
}
*/
</style>