import axios from 'axios'
//import { reject, resolve } from 'core-js/fn/promise';
//import {format} from 'date-fns'
import { formatISO } from 'date-fns'
/*
GET: api/Device/{id}/actual
vrátí aktualní hodnoty pro dané zařízení
vyžaduje autorizaci tokenem
příklad: http://192.168.1.130/api/device/3/actual
odpověď: 
{
    "startTime": "2021-05-27T10:35:55",
    "endTime": "2021-05-27T10:46:18",
    "actualSolar": 121.0,
    "actualGrid": 0.0,
    "actualTemp1": 57.0,
    "actualTemp2": 43.0,
    "heatingState": 0,
    "errorCode": ""
}
*/
const resolvePromisesSeq = async (tasks) => {
    const results = [];
    for (const task of tasks) {
      results.push(await task);
    }
    return results;
  };

export default {
    
    state: {
        deviceList: null,
        simList: null,
        selectedDevice: null,
        selectedDeviceId: localStorage.getItem('lastDeviceId') || null,
        filterType: localStorage.getItem('filterType') || 0,
        filterFrom: localStorage.getItem('filterFrom') || null,
        filterTo: localStorage.getItem('filterTo') || null,
        deviceData: null,
        actualDeviceData: null,
        actualDeviceDataList: [],
        legendHide: { 
            0:localStorage.getItem('datasetHide'+0) == "true",
            1:localStorage.getItem('datasetHide'+1) == "true",
            2:localStorage.getItem('datasetHide'+2) == "true",
            3:localStorage.getItem('datasetHide'+3) == "true",
            'Tank1LimitAc': !(localStorage.getItem('datasetHideTank1LimitAc') == "false"),
            'Tank1LimitSolar':!(localStorage.getItem('datasetHideTank1LimitSolar') == "false"),
            'Tank2LimitSolar':!(localStorage.getItem('datasetHideTank2LimitSolar') == "false")
        },
        showChartTooltip: localStorage.getItem('showChartTooltip') == "true",
        getDataAbortController: null, //new AbortController(),
        getActualDataAbortController: null //new AbortController(),
        //graphVProps: null
    },
    getters: {
        deviceList: (state) => {
            return state.deviceList
        },
        simList: (state) => {
            return state.simList
        },
        selectedDevice: (state) => {
            if ((state.selectedDeviceId > -1) && state.deviceList) {
                var device = state.deviceList.find( ({id}) => id === state.selectedDeviceId)
                return device
            } else
                return null
        },
        selectedDeviceId: (state) => {
            return state.selectedDeviceId
        },
        filterType: (state) => {
            return state.filterType
        },
        filterFrom: (state) => {
            return state.filterFrom
        },
        filterTo: (state) => {
            return state.filterTo
        },
        deviceData: (state) => {
            return state.deviceData
        },
        actualDeviceData: (state) => {
            return state.actualDeviceData
        },
        actualDeviceDataList: (state) => {
            return state.actualDeviceDataList
        },
        legendHide: (state) => {
            return state.legendHide
        },
        showChartTooltip: (state) => {
            return state.showChartTooltip
        },
        /*legendHideTank1Limit: (state) => {
            return state.legendHideTank1Limit
        },
        legendHideTank2Limit: (state) => {
            return state.legendHideTank2Limit
        },*/
        // graphVProps: (state) => {
        //     return state.graphVProps
        // },
    },
    actions: {
        set_selected_device_id(context, id){
            context.commit('set_selected_device_id', id)
        },
        //set_selected_device(context, device){
        //    context.commit('set_selected_device', device)
        //},
        set_selected_filter(context, payload){
            console.log(payload)
            context.commit('set_device_filter', payload)
        },
        get_actual_device_data(context, id) {
            //context.commit('set_actual_device_data', {loading:true, loadingError:null})
            if (this.getActualDataAbortController) {
                this.getActualDataAbortController.abort();
                console.log('get_actual_device_data - abort called')
            }
            this.getActualDataAbortController = new AbortController();
            const signal = this.getActualDataAbortController.signal;
            return new Promise((resolve, reject) => {
                axios
                  .get('device/'+id+'/actual', {signal})
                  .then((response) => {
                    console.log(response, 'getActualDataAbortController', this.getActualDataAbortController) 
                    this.getActualDataAbortController = null;  
                    var {status = null, data = null} = response
                    if (status === 200) {
                      console.log(data);
                      context.commit('set_actual_device_data', data)  
                      resolve(true);
                    } else /*if(status === 400)*/ {
                      console.log(data);
                      context.commit('set_actual_device_data', null)
                      reject(data); 
                    }
                  },(error) => {
                        // reject
                        console.log(error) 
                        this.getActualDataAbortController = null;  
                        var {response = null} = error || {}
                        console.log('get_actual_device_data rejected', response)  
                        var {status = null, data = null} = response || {}
                        if (status === 500) {
                            console.log(data);
                            console.log('get_actual_device_data reject error', response)
                            context.commit('set_actual_device_data', {loading:false, loadingError:"Internal server error"})
                            reject(data); 
                        } else {
                                console.log(data);
                                console.log('get_device_data reject error', response)
                                context.commit('set_actual_device_data', {loading:false, loadingError:error||"Loading error"})
                                reject(data); 
                            }
                        }
                  )
                  .catch(error => {
                    reject(error);
                  });
            });
            
        },
        abort_device_data(context){
            context.commit("abort_get_data")
        },
        get_device_data(context, payload) {
            console.log(payload)
            //context.commit("abort_get_data")
            
            var {id, filterType, filterFrom = null, filterTo = null} = payload
            var params = {type: filterType} 
            if (filterType == 4) {
                if ((filterFrom == null) || (filterTo == null)) {
                    // custom filter, interval not selected
                    context.commit('set_device_data', null)
                    return false
                }
                var filterFromDate = new Date(filterFrom)
                var filterToDate = new Date(filterTo)
                // check if from < to, otherwise swap both dates
                if (filterToDate < filterFromDate) {
                    var temp = filterToDate
                    //payload.filterTo = payload.filterFrom
                    //payload.filterFrom = temp

                    filterToDate = filterFromDate
                    filterFromDate = temp
                }
                //filterToDate.setDate( filterToDate.getDate() + 1 );
                params.p1 = formatISO(filterFromDate, { representation: 'date' })//format(filterFromDate, 'yyyy-MM-dd')
                params.p2 = formatISO(filterToDate, { representation: 'date' })//format(filterToDate, 'yyyy-MM-dd')
                console.log("get_device_data filter to:", params.p2, params.p2)
            }
            context.commit('set_device_filter', payload)
            context.commit('set_device_data', {loading:true})

            if (this.getDataAbortController) {
                this.getDataAbortController.abort();
                console.log(payload, 'get_device_data - abort called')
            }

            this.getDataAbortController = new AbortController();
            const signal = this.getDataAbortController.signal;

            return new Promise((resolve, reject) => {
                axios
                  .get('device/'+id+'/filter', {
                    signal: signal, //context.state.getDataAbortController.signal,
                    params: params
                    })
                  .then((response) => { // fulfilled
                        console.log('fulfilled', response, 'getDataAbortController', this.getDataAbortController) 
                        this.getDataAbortController = null; 
                        var {status = null, data = null} = response || {}
                        if (status === 200) {
                        console.log(data);
                        context.commit('set_device_filter', payload)  
                        context.commit('set_device_data', data)  
                        resolve(true);
                        } else {
                            console.log(data);
                            console.log('get_device_data status error', response)
                            context.commit('set_device_data', {loading:false, loadingError:response||"Loading error"})
                            reject(data); 
                        }
                    },(error) => { // rejected
                        var {response = null} = error || {}
                        console.log('rejected', response) 
                        this.getDataAbortController = null; 
                        var {status = null, data = null} = response || {}
                        if (status === 500) {
                            console.log(data);
                            console.log('get_device_data reject error', response)
                            context.commit('set_device_data', {loading:false, loadingError:"Internal server error"})
                            reject(data); 
                        } else {
                                console.log(data);
                                console.log('get_device_data reject error', response)
                                context.commit('set_device_data', {loading:false, loadingError:error||"Loading error"})
                                reject(data); 
                            }
                        }
                  )
                  .catch(error => {
                    console.log('get_device_data catch error', error)
                    reject(error);
                    context.commit('set_device_data', {loading:false, loadingError:error})
                  });
            });
        },
        get_device_list(context) {
            return new Promise((resolve, reject) => {
                context.commit('set_device_data', {loading:false})
                context.commit('set_actual_device_data', {})
                axios
                  .get('device')
                  .then(({ data, status }) => {
                    if (status === 200) {
                      console.log(data);
                      context.commit('set_device_list', data)  
                      resolve(true);
                    } else /*if(status === 400)*/ {
                        console.log(data);
                        context.commit('set_device_list', null)
                        reject(data); 
                      }
                  })
                  .catch(error => {
                    reject(error);
                  });
              });
        },
        create_device: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                axios.post('device', payload)
                .then(({ data, status }) => {
                    if (status === 200) {
                        console.log(axios)
                        console.log(data);
                        commit('add_device', data)
                        resolve(data);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
        },
        update_device: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                axios.post('device', payload)
                .then(({ data, status }) => {
                    if (status === 200) {
                        console.log(axios)
                        console.log(data);
                        commit('add_device', payload)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
        },
        delete_device: ({ commit }, id) => {
            return new Promise((resolve, reject) => {
                axios.delete('device/'+id)
                .then(({ data, status }) => {
                    if (status === 200) {
                        console.log(axios)
                        console.log(data);
                        commit('delete_device', id)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
        },
        get_sim_list({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                  .get('sim/DataByDeviceId/'+ id)
                  .then(({ data, status }) => {
                    if (status === 200) {
                      console.log(data);
                      commit('set_sim_list', data)  
                      resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
              });
        },
        add_sim: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                axios.post('sim', payload)
                .then(({ data, status }) => {
                    if (status === 200) {
                        console.log(axios)
                        console.log(data);
                        commit('add_sim', payload)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
        },
        delete_sim: ({ commit }, id) => {
            return new Promise((resolve, reject) => {
                axios.delete('sim/' + id)
                .then(({ data, status }) => {
                    if (status === 200) {
                        console.log(axios)
                        console.log(data);
                        commit('delete_sim', id)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
        },
        add_sim_local: ({ commit }, payload) => {
            // add to fake sim list
            commit('add_sim', payload)
            return true
        },
        delete_sim_local: ({ commit }, number) => {
            // delete from fake sim list
            commit('delete_sim_number', number)
            return true
        },
        /*save_sim: async (context, sim) =>{
            try {
                const resp = await axios.post('sim', sim)
        
                console.log(resp.data);
                return resp.data
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
            await axios.post('sim', sim)
                .then(({ data, status }) => {
                    if (status === 200) {
                        console.log(axios)
                        console.log(data);
                        commit('add_sim', payload)
                        resolve(true);
                    }
                  })
                  .catch(error => {
                    reject(error);
                  });
        },*/
        save_local_simlist: (context, deviceId) => {
            return new Promise((resolve, reject) => {
                var promises = context.state.simList.map((sim) => {
                    var payload = { number: sim.number, deviceId: deviceId}
                    return new Promise((resolve, reject) => {
                        axios.post('sim', payload)
                        .then(({ data, status }) => {
                            if (status === 200) {
                                console.log(axios)
                                console.log(data);
                                //commit('add_sim', payload)
                                resolve(true);
                            } else {
                                resolve(false)
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                    });
                })

                try {
                    const simsRes = resolvePromisesSeq(promises);
                    console.log('saveSimsRes', simsRes) 
                    resolve(simsRes)
                } catch (error) {
                    console.error('save_local_simlist', error)
                    reject(error)
                }
            })
        },
        clear_sim_list({ commit }){
            commit('set_sim_list', [])
        },
        set_legend_hide({ commit }, payload){
            // payload = {index, state};
            localStorage.setItem("datasetHide"+payload.index, payload.state == true)
            commit('set_legend_hide', payload)
        },
        set_show_chart_tooltip({ commit }, payload){
            // payload = {state};
            localStorage.setItem("showChartTooltip", payload.state == true)
            commit('set_show_chart_tooltip', payload)
        },
        get_actual_device_data_list: (context, /*payload*/) => {
            return new Promise((resolve, reject) => {
                var promises = context.state.deviceList.map((device) => {
                    if (device && device.sims && (device.sims.length > 0)){
                        var id = device.id
                        return new Promise((resolve, reject) => {
                            axios
                            .get('device/'+id+'/actual')
                            .then((response) => {
                                console.log(response)  
                                var {status = null, data = null} = response
                                data.id=id
                                if (status === 200) {
                                console.log(data);
                                //context.commit('set_actual_device_data', data)
                                
                                resolve(data);
                                } else /*if(status === 400)*/ {
                                console.log(data);
                                //context.commit('set_actual_device_data', null)
                                reject(data); 
                                }
                            },(error) => {
                                    // reject
                                    console.log(error)  
                                    var {response = null} = error || {}
                                    console.log('get_actual_device_data rejected', response)  
                                    var {status = null, data = null} = response || {}
                                    if (status === 500) {
                                        console.log(data);
                                        console.log('get_actual_device_data reject error', response)
                                        data.id = id
                                        //context.commit('set_actual_device_data', {loading:false, loadingError:"Internal server error"})
                                        reject(data); 
                                    } else {
                                            console.log(data);
                                            console.log('get_device_data reject error', response)
                                            //context.commit('set_actual_device_data', {loading:false, loadingError:error||"Loading error"})
                                            data.id=id
                                            reject(data); 
                                        }
                                    }
                            )
                            .catch(error => {
                                reject(error);
                            });
                            
                            
                            
                        });
                    }
                })

                try {
                    resolvePromisesSeq(promises).then((result)=>{
                        console.log('get_actual_device_data_list', result) 
                        context.commit('set_actual_device_data_list', result)
                        resolve(result)
    
                    }).catch( (error) => {
                        console.error('get_actual_data_list', error)
                        reject(error)
                    })
                } catch (error) {
                    console.error('set_actual_device_data_list', error)
                    reject(error)
                }
            })
        },

    },
    mutations: {
        set_selected_device_id(state, id) {
            state.selectedDeviceId = id
            localStorage.setItem('lastDeviceId', id)
        },
        //set_selected_device(state, device) {
        //    state.selectedDevice = device
        //},
        set_actual_device_data(state, deviceData) {
            state.actualDeviceData = deviceData
        },
        set_device_data(state, deviceData) {
            state.deviceData = deviceData
        },
        set_device_filter(state, payload) {
            var {filterType = 0, filterFrom = null, filterTo = null} = payload
            state.filterType = filterType
            state.filterFrom = filterFrom
            state.filterTo = filterTo
            console.log('set_device_filter', payload)
            localStorage.setItem('filterType', filterType)
            localStorage.setItem('filterFrom', filterFrom)
            localStorage.setItem('filterTo', filterTo)
        },
        set_device_list(state, deviceList) {
            state.deviceList = deviceList
        },
        set_sim_list(state, simList) {
            state.simList = simList
        },
        add_device(state, newDevice) {
            //console.log(newDevice)
            state.deviceList = [...state.deviceList, newDevice]
        },
        delete_device(state, id) {
            //console.log(newDevice)
            let deviceIdx = state.deviceList.findIndex(obj => obj.id == id);
            if (deviceIdx >- 1){
                // odebrat sim ze seznamu
                state.deviceList.splice(deviceIdx, 1);
                
            }
        },
        add_sim(state, payload) {
            //console.log(newDevice)
            if (payload.deviceId == null) {
                if (state.simList){
                    // najdi cislo jestli existuje 
                    let simIdx = state.simList.findIndex(obj => obj.number == payload.number);
                    if (simIdx < 0){
                        // pridat sim do seznamu
                        state.simList = [...state.simList, payload]
                        
                    }
                } else {
                    state.simList = [payload]
                }
            }
            let device = state.deviceList.find(obj => obj.id == payload.deviceId);
            if (device!=null){ // nebo undefined?
                // todo pridat sim do device
            }

            //state.deviceList = [...state.deviceList, newDevice]
        },
        delete_sim(state, id) {
            //console.log(newDevice)
            let simIdx = state.simList.findIndex(obj => obj.id == id);
            if (simIdx >- 1){
                // odebrat sim ze seznamu
                state.simList.splice(simIdx, 1);
                
            }
        },
        delete_sim_number(state, number) {
            //console.log(newDevice)
            let simIdx = state.simList.findIndex(obj => obj.number == number);
            if (simIdx >- 1){
                // odebrat sim ze seznamu
                state.simList.splice(simIdx, 1);
                
            }
        },
        set_legend_hide(state, payload){
            state.legendHide[payload.index] = payload.state
            //state.legendHide = [...state.legendHide]
        },
        set_show_chart_tooltip(state, payload){
            state.showChartTooltip = payload.state
        },
        set_actual_device_data_list(state, payload) {
            console.log('set_actual_device_data_list', payload)
            state.actualDeviceDataList = payload
        },
        // abort_get_data(state){
        //     state.getDataAbortController.abort()
        //     console.log('get_data_aborted')
        //     state.getDataAbortController = new AbortController()
        // }
    }
}

