//import axios from 'axios'

export default {
    state: {
        lang: localStorage.getItem('lang') || 'en',
        trans: null,
        colors: {
            solar: '#f87979',
            grid: '#17a2b8',
            tank_1_temperature: 'blue',
            tank_2_temperature: 'green',
            tank_1_temperature_limit_ac: '#137e8f',
            tank_1_temperature_limit_solar: '#f58e43',//'#dd5b5b',
            tank_2_temperature_limit_solar: '#dd5b5b',
        }
    },
    getters: {
        lang: (state) => {
            return state.lang
        },
        trans: (state) => {
            return state.trans
        },
        colors: (state) => {
            return state.colors
        }
    },
    actions: {
        set_lang(context, lang) {
            context.commit('set_lang', lang)
        },
        set_trans(context, trans) {
            console.log('i18ne object action', trans)
            context.state.trans = trans
            //context.commit('set_trans', trans)
        }
    },
    mutations: {
        set_lang(state, lang) {
            state.lang = lang
            localStorage.setItem('lang', lang)
        },
        set_trans(state, trans) {
            console.log('trans object mutation', trans)
            state.trans = trans
        }
    }
}

