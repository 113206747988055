<template>
  <b-navbar toggleable="lg" type="light"  class="navbar-color pt-2 pb-1">
    <b-navbar-brand href="/" class="mb-0">
      <img src="/slunicko.svg" class="kerberos-logo" alt=""/> 
      <img src="/Logo_SK_cz3.svg" class="kerberos-brand" alt="Solar Kerberos"/>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav >
      <b-navbar-nav v-if="!isLogged" class="ml-auto" >
        <b-nav-item to="/login" link-classes="navbar-color" >{{ $t("login") }}</b-nav-item>
        <b-nav-item to="/register" link-classes="navbar-color">{{ $t("signup") }}</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav v-if="isLogged" class="ml-auto">
        <b-nav-item to="/dashboard" link-classes="navbar-color">{{ $t("dashboard") }}</b-nav-item>
        <b-nav-item-dropdown link-classes="navbar-color" toggle-class="navbar-color" :text="$t('settings')" right no-caret>
          <b-dropdown-item to="/devices" >{{ $t("devices") }}</b-dropdown-item>
          <b-dropdown-item to="/profile" >{{ $t("profile") }}</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item v-if="isAdmin" to="/users" link-classes="navbar-color">{{ $t("users") }}</b-nav-item>
        <b-nav-item href="javascript:void(0)" link-classes="navbar-color" @click="logout">{{ $t("logout") }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-nav-item-dropdown text="Lang" no-caret right>
          <template #button-content>
            <lang-flag :iso="get_language()" :squared="false"/>
          </template>
          <b-dropdown-item href="#" v-on:click.prevent="changeLanguage('cs')">Čeština</b-dropdown-item>
          <b-dropdown-item href="#" v-on:click.prevent="changeLanguage('en')">English</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>  
</template>
<script>
//<b-navbar toggleable="lg" type="light" fixed="top" class="navbar-color pt-2 pb-1">
import {mapGetters} from 'vuex'
import LangFlag from 'vue-lang-code-flags';

export default {
    name: 'Nav',
    components:{
      LangFlag
    },
    data() {
      return {
        intervalPoll: null
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        },
        changeLanguage(lang){
          this.$i18n.i18next.changeLanguage(lang);
          this.$store.dispatch("set_lang", lang)
          this.$store.dispatch("refresh")
          
        },
        get_language(){
          return this.$i18n.i18next.language
        }
    },
    computed: {
        ...mapGetters(['user','isAdmin', 'isLogged'])
    },
    /*watch: {
      user: {
        handler(val){
          console.log('watcher user', val)
        },
        deep: true
      }
    },*/
    //beforeDestroy() {
    unmounted() {
       clearInterval(this.intervalPoll)
    },
    mounted() {
      if (this.intervalPoll) return // do not run multiple times
      this.intervalPoll = window.setInterval(() => {
        console.log('check login time')
        // check login token
        var token = localStorage.getItem('token')
        if (token) {
          //console.log("fire check_login_time")
          this.$store.dispatch("check_login_time").then((success)=>{
            //console.log("check_login_time return", success)
            //console.log("isLogged", this.isLogged)
            if (success)
              this.$router.push('/login')
          })
        }
      }, 10000) // 10s in ms
    }
}
</script>