<template>
    <div>
        <b-row v-if="errorsArr.length>0">
            <b-col>
                <stats-card class="mb-2 mb-md-4"
                    :title="$t('kerberos_warnings')"
                    :show-footer-line="true">
                    <template slot="footer">
                        <div class="d-flex flex-row ml-1 align-items-center">
                            <div class="d-flex flex-column bd-highlight mb-1 mr-1 mt-1 p-2" style="font-size: 20px;">
                                    <b-icon icon="exclamation-triangle-fill" scale="2" variant="warning"></b-icon>
                            </div>
                            <div class="d-inline-flex ml-2">
                                <ul class="mb-0">
                                    <li v-for="error in errorsArr" :key="error">
                                        {{ $t(error) }} 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </template>
                </stats-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import consts from '../../consts.js'
import StatsCard from '../Common/StatsCard';
import {mapGetters} from 'vuex'
import { format, parseISO, startOfToday, isAfter } from 'date-fns'

export default {
    name: 'DeviceWarning',
    components: {
        StatsCard,
    },
    data() {
        return {

        }
    },
    props: ['actualDeviceData'],
    computed: {
        ...mapGetters(['lang']),
        errorsArr(){ 
            try {
                var result = []
                var {displayEndOfSim = false} = this.actualDeviceData || {}
                var {endOfSimDate = '0001-01-01'} = this.actualDeviceData || {}
                
                if (displayEndOfSim) {
                    endOfSimDate = parseISO(endOfSimDate)
                    console.log('endOfSimDate', endOfSimDate, startOfToday(), isAfter(startOfToday(), endOfSimDate))
                    if (!isAfter(startOfToday(), endOfSimDate)) {
                        var endOfSimDateStr = format(endOfSimDate, 'P', {locale:consts.locales[this.lang]})
                        result.push(this.$t('sim_expiring') + endOfSimDateStr)
                    }    
                }
                console.log('device warnings', result)
                return result 
            } catch (error) {
                return []                
            }
        },

    }
}
</script>
<style scoped>
ul {
  list-style: none; 
  padding: 0;
  margin: 0;
}

li {
  padding-left: 1rem;
}

li::before {
  content: "•"; 
  padding-right: 0.5rem;
  color: rgb(143, 143, 145); 
  
}

</style>