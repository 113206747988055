<template>
    <form @submit.prevent="handleSubmit">
        <Error v-if="error" :error="error"/>
        <div v-if="message" class="alert alert-success" role="alert">
            {{message}}
        </div>
        <h3>{{$t('forgot_password')}}</h3>
        <b-form-group
                :label="$t('email')"
                label-for="email-input"
                :invalid-feedback="$t(emailFeedback)"
                :state="emailState"
                >
                <b-form-input type="email" class="form-control" v-model="email" :placeholder="$t('user_email')" required/>
        </b-form-group>
        <b-button block variant="primary" :disabled="submitDisabled" type='submit'>{{$t('submit')}}</b-button>
        <div class="d-flex justify-content-between">
            <div class="d-inline-flex"> 
                <p class="forgot-password text-right">
                    <router-link to="register">{{ $t("signup") }}</router-link>
                </p>
            </div>
            <div class="d-inline-flex">
                <p class="forgot-password">
                    <router-link to="login">{{ $t("login") }}</router-link>
                </p>
            </div>
        </div>
    </form>    
</template>
<script>
import axios from 'axios'
import Error from './Error'

export default {
    name: 'Forgot',
    components: {
        Error
    },
    data() {
        return {
            email: '',
            message: '',
            error: '',
            emailState: null,
            emailFeedback: null,
            submitDisabled: false
        }
    },
    watch: {
        email: function() {
            this.checkEmailValidity()
        },
    },
    methods: {
        checkEmailValidity() {
            var valid = true
            this.email = this.email.trim()
            var regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
            valid = regex.test(this.email);
            if (!valid)
                this.emailFeedback = 'email_bad_format' //this.$t('email_bad_format')
            this.emailState = valid
            return valid
        },
        checkFormValidity() {
            console.log('Validate start')
            var valid = this.checkEmailValidity()
            return valid
        },
        parseFormErrors(errors){
            console.log('parse errors', errors)
            this.emailState = errors.Email == true
            if (typeof(errors.Email) == 'object')
                this.emailFeedback = errors.Email[0]
        },
        async handleSubmit() {
            try{
                this.message = null
                this.error = null
                if (!this.checkFormValidity()) {
                    return false
                }
                this.submitDisabled = true
                const response = await axios.post('user/forgot', {
                    email: this.email,
                })
                console.log(response);
                this.message = this.$t('email_sent')
                this.email = ""
                this.$nextTick(() => {
                    this.emailState = null
                    this.emailFeedback = null
                });
                
                
            } catch (error) {
                console.log('failed to register', error)
                /*if (error && error.response && error.response.data) {
                    // some of parameter wrong
                    if (error.response.data.errors) {
                        this.parseFormErrors(error.response.data.errors)
                    } else {
                        this.error = error.response.data
                    }
                    
                } else*/ if (error && error.response && error.response.status == 400) {
                    this.error = this.$t('email_not_exist')
                } else {
                    this.error = error
                }
                
                //this.error = this.$t('error_occured')
            }
            this.submitDisabled = false
        }
    }
    
}
</script>