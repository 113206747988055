<template>
    <ul class="d-flex justify-content-center align-items-center flex-wrap">
        <ChartLegendItem v-for="(dataset) in sortedData" :key="dataset.order" :dataset="dataset" :index="dataset.legendIndex" :variant="variant"/>
    </ul>
  
</template>

<script>
import {mapGetters} from 'vuex'
import ChartLegendItem from './ChartLegendItem.vue'


export default {
    name: 'ChartLegend',
    components: {
        ChartLegendItem
    },
    data() {
        return {
        
        }
    },
    props: ['legendData', 'loading', 'variant'],
    computed: {
        ...mapGetters(['user', 'lang', 'legendHide']),
        ...mapGetters(['actualDeviceData','deviceData', 'filterType', 'filterFrom', 'filterTo', 'selectedDeviceId']),
        sortedData(){
            this.legendData.forEach((dataset, index) => {
                dataset.index = index
            })

            var result = [...this.legendData]
            result.sort((a, b) => {
                var aVal = a.yAxisID =='temp'?100:0
                var bVal = b.yAxisID =='temp'?100:0
                return (a.order + aVal) - (b.order + bVal);
            });
            return result
        }
    },
    methods: {
        labelClicked(index,name){
            console.log('labelClicked', index,name)
            this.$store.dispatch("set_legend_hide", {index:index, state: !this.legendHide[index]})
        },
    }
}
</script>

<style scoped>
ul {
  list-style: none; 
  padding: 0;
  margin: 0;
}

li {
  padding-left: 1rem;
}

/*li::before {
  content: "•"; 
  padding-right: 0.5rem;
  color: rgb(143, 143, 145); 
  
}
*/
</style>