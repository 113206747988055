import { enGB, cs, fr } from 'date-fns/locale'
const locales = { en:enGB, cs, fr }

export default {
    // device types
    DEVICE_TYPE_STANDARD: 0,
    DEVICE_TYPE_POWER: 1,
    // heating states
    HEATING_STATE_NONE: 0, // - None (Netopí se)
    HEATING_STATE_SOLAR: 1, // - Solar (Ohřívá se solárem)
    HEATING_STATE_GRID: 2, // - Grid (Ohřívá se ze sítě)
    // locales
    locales
}