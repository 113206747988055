import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from './components/Dashboard/Dashboard.vue'
import Auth from './components/Auth/Auth.vue'
import Login from './components/Auth/Login.vue'
import Register from './components/Auth/Register.vue'
import RegisterAfter from './components/Auth/RegisterAfter.vue'
import Forgot from './components/Auth/Forgot.vue'
import Reset from './components/Auth/Reset.vue'
import Confirm from './components/Auth/Confirm.vue'
//import DeviceSetup from './components/Devices/DeviceSetup.vue'
//import Users from './components/Users/Users.vue'
//import Profile from './components/Profile/Profile.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {path: '/', component: Dashboard},
        {path: '/dashboard', component: Dashboard,
            children: [
                {
                    path: ':deviceId', 
                    component: Dashboard, 
                    //props: true
                    props: route => ({ 
                        query: route.query,
                        s: route.query.s,
                        g: route.query.g,
                        t1: route.query.t1,
                        t2: route.query.t2,
                     })
                }
            ]
        },
        {
            path: '/user', component: Auth,
            children: [
                {path: '/login', component: Login, name:'login'},
                {path: '/register', component: Register},
                {path: '/registerafter', component: RegisterAfter},
                {path: '/forgot', component: Forgot},
                {path: '/reset/:token', component: Reset},
                {path: '/confirm/:token', component: Confirm}
            ]
        },
        {
            path: '/devices', 
            //component: DeviceSetup},
            component: () => import(/* webpackChunkName: "devices" */ './components/Devices/DeviceSetup.vue')
        },
        {
            path: '/users', 
            //component: Users
            component: () => import(/* webpackChunkName: "users" */ './components/Users/Users.vue')
        },
        {
            path: '/profile', 
            //component: Profile,
            component: () => import(/* webpackChunkName: "profile" */ './components/Profile/Profile.vue')
        },
        
    ]
})