import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from './axios'
//import store from './vuex'
import store from './stores/store'
//import i18next from 'i18next'
//import VueI18Next from '@panter/vue-i18next'
import i18n from './i18n'
//import VueMoment from 'vue-moment'
import 'chartjs-adapter-date-fns'

import VTooltip from 'v-tooltip'

Vue.config.productionTip = false

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//Vue.use(VueI18Next);
Vue.use(i18n);



//Vue.use(VueMoment);
//Vue.use(i18n);
Vue.use(VTooltip);

// TODO upravit message
let isRefreshing = false;
let subscribers = [];

//store.i18n = i18n

const acceptLanguage = function(){
  var lang = i18n.i18next.language
  
  /*var lang = localStorage.getItem('lang')
  if (!lang) {
      var result = navigator.language || navigator.userLanguage
  } else {
      result = 'en-GB'
  }*/
  var result = 'en-GB'
  if (lang == 'cs') result = 'cs-CZ'
  return result
}

axios.interceptors.request.use(/*request => {
  console.log('Starting Request:', request)
  //console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
}*/
  config => {
    /*const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
    }
    config.headers['Content-Type'] = 'application/json';*/
    config.headers['Accept-Language'] = acceptLanguage()
    console.log('Starting Request:', config)
    return config;
  },
  error => {
    Promise.reject(error)
  },
  { synchronous: true }
)

axios.interceptors.response.use(
  response => {
    var {
      config 
    } = response || {}
    const originalRequest = config
    console.log('Response:', response)
    //console.log('Response:', JSON.stringify(response, null, 2))
    if (!isRefreshing) {
      if (!originalRequest.url.includes("login")) {
        if (localStorage.getItem('token')) { // user is logged and has token
          isRefreshing = true
          store
            .dispatch("refresh")
            .then((/*{ status }*/) => {
              isRefreshing = false;
              // if (status === 200 || status == 204) {
              //   isRefreshing = false;
              // }
              subscribers = [];
            })
            .catch(error => {
              console.error(error);
              isRefreshing = false;
            });
        }
      }
    }

    return response;
  },
  err => {
    console.log("axios error interceptor", err)
    var {
      config,
      response
    } = err || {}
    console.log('config, response',config, response)
    var {status, data} = response || {}
    console.log(status, data)
    const originalRequest = config;

    if (data?.message === "Missing token") {
      router.push({ name: "login" });
      return Promise.reject(err);
    }

    if (originalRequest.url.includes("login")) {
      return Promise.reject(err);
    }
    if (originalRequest.url.includes("register")) {
      return Promise.reject(err);
    }

    

    if (status === 401 && data?.message === "Expired token") {
      if (!isRefreshing) {
        isRefreshing = true;
        store
          .dispatch("refresh")
          .then(( status ) => {
            if (status === 200 || status == 204) {
              isRefreshing = false;
            }
            subscribers = [];
          })
          .catch(error => {
            console.error(error);
          });
      }

      const requestSubscribers = new Promise(resolve => {
        subscribeTokenRefresh(() => {
          resolve(axios(originalRequest));
        });
      });

      onRefreshed();

      return requestSubscribers;
    }

    if (status === 401) {
      console.log("Unauthorized", status, data)
      router.push({ name: "login" });
      return Promise.reject(err);
    }

    //throw new Error(response) 
    //return err
    return Promise.reject(err);

  }
);

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed() {
  subscribers.map(cb => cb());
}

subscribers = [];



//store.$i18n = i18n

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
})
//app.$store.dispatch("set_trans", i18next)

app.$mount('#app') 


