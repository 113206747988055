<template>
    <div>
        <Error v-if="error" :error="error"/>
        <div v-if="!isConfirmed" >
            <h3>{{$t('email_verification.heading')}}</h3>
            <p v-if="!error">
                {{$t('email_verification.text3')}}
            </p>
            <p v-if="error">
                {{$t('email_verification.token_expired')}}
            </p>
        </div>
        <form v-if="isConfirmed" @submit.prevent="handleSubmit">
            <h3>{{$t('email_verification.heading')}}</h3>
            <p>
                {{$t('email_verification.text1')}}
            </p>
            <p>
                {{$t('email_verification.text2')}}
            </p>
            <button class="btn btn-primary btn-block">{{$t('email_verification.verify_button_text')}}</button>
        </form>      
    </div>
</template>
<script>
import axios from 'axios'
import Error from './Error'

export default {
    name: 'Confirm',
    components: {
        Error,
        //Message
    },
    data() {
        return {
            isConfirmed: false, 
            error: null   
        }
    },
    methods: {
        async handleSubmit(){
            /*const data = {
                token: this.$route.params.token 
            }
            //console.log('submitted data', data)
            await axios.get('user/confirm/' + data.token)
                .then((success) => {
                    console.log(success)
                    if (success && success.status === 200) {
                        console.log(success.data);
                        //context.commit('set_user_list', data)  
                        this.$router.push('/login')
                    } else {
                        this.error = this.$t("email_verification.token_expired")
                        //alert(this.$t('email_verification.token_expired'))
                        console.log(success); 
                    } 
                })
                .catch(error => {
                    if (error) {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    }
                    alert(error);
                });*/


            //console.log(response)  
            this.$router.push('/login')  
        },
    },
    async created(){
        console.log('created event confirm', this.$route.params.token)
        const data = {
            token: this.$route.params.token 
        }
        await axios.get('user/confirm/' + data.token)
            .then((success) => {
                console.log(success)
                if (success && success.status === 200) {
                    console.log(success.data);
                    this.isConfirmed = true
                    //context.commit('set_user_list', data)  
                    //this.$router.push('/login')
                } else {
                    this.error = this.$t("error_occured")
                    //alert(this.$t('email_verification.token_expired'))
                    console.log(success); 
                } 
            })
            .catch(error => {
                if (error) {
                    var msg = error;
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        if (error.response.status == 400) {
                            msg = this.$t('error_occured')
                        } 
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    //console.log(error.config);
                    this.error = msg;
                }
                
            });

    }

}
</script>