<template>
<div>
    <b-form inline class="mb-2">
            <b-button-group id="input-group-1" class="mb-2  mr-sm-2">
                <b-button  variant="light" @click.prevent="leftBtn" :disabled="disabledLeft">
                    <b-icon icon="chevron-left" :title="$t('left')"></b-icon>
                    <span class="visible-lg-inline"> </span>
                </b-button>
                <b-button  variant="light" @click.prevent="rightBtn" :disabled="disabledRight">
                    <b-icon icon="chevron-right" aria-hidden="true" :title="$t('right')"></b-icon>
                    <span class="visible-lg-inline"> </span>
                </b-button>
                <b-form-select id="filter" name="filterNames" v-model="selectedFilter" @change="onChange($event)" @input="onInput($event)" :options="options" class="mr-sm-2 ml-1" >
                </b-form-select>
            </b-button-group>

        <b-form-group v-if="!disabled" 
            id="input-group-3" 
            :label="$t('filter.from')" 
            label-for="from-datepicker" 
            label-class="mb-2 mr-sm-2"
            >
            
            <b-form-datepicker 
                id="from-datepicker" 
                v-model="selectedFrom"
                v-bind="labels[localeCode] || {}" 
                :disabled="disabled" 
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :min="fromMin"
                :max="fromMax"
                class="mb-2  mr-sm-2"
                today-button
                close-button
                no-flip
                :label-today-button="$t('filter.today')"
                :label-close-button="$t('close')"
                :locale="localeCode" 
                :label-no-date-selected="$t('no_date_selected')">
            </b-form-datepicker>
        </b-form-group>
        <b-form-group v-if="!disabled" 
            id="input-group-4" 
            :label="$t('filter.to')" 
            label-for="to-datepicker" 
            label-class="mb-2 mr-sm-2"
            >
            <b-form-datepicker 
                id="to-datepicker" 
                v-model="selectedTo"
                v-bind="labels[localeCode] || {}"  
                :disabled="disabled" 
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                class="mb-2 mr-sm-2 " 
                :min="toMin"
                :max="toMax"
                today-button
                close-button
                no-flip
                :label-today-button="$t('filter.today')"
                :label-close-button="$t('close')"
                :locale="localeCode" 
                :label-no-date-selected="$t('no_date_selected')" 
                >
            </b-form-datepicker>
        </b-form-group>
    </b-form> 
    </div>   
</template>

<script>
//import subMonths from 'date-fns/esm/subMonths/index.js'
//import startOfMonth from 'date-fns/esm/startOfMonth/index.js'
//import lastDayOfMonth from 'date-fns/esm/lastDayOfMonth/index.js'
import { 
    startOfMonth, 
    lastDayOfMonth, 
    subMonths, 
    differenceInDays, 
    //differenceInMonths, 
    formatISO, 
    addMonths, 
    addDays, 
    //addYears 
    } from 'date-fns'
//        <b-form-select id="device" v-model="selected" :options="deviceList"></b-form-select>
//<b-form-select id="device" name="devices" v-model="selectedDevice" @change="onChange($event)">
import {mapGetters} from 'vuex'
import consts from '../../consts.js'
import startOfDay from 'date-fns/startOfDay'
//import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'

const dateMinLimit = new Date('2010-01-01')

export default {
    name: 'FilterSelect',
    data() {
      return {
        labels: {
          /*de: {
            labelPrevDecade: 'Vorheriges Jahrzehnt',
            labelPrevYear: 'Vorheriges Jahr',
            labelPrevMonth: 'Vorheriger Monat',
            labelCurrentMonth: 'Aktueller Monat',
            labelNextMonth: 'Nächster Monat',
            labelNextYear: 'Nächstes Jahr',
            labelNextDecade: 'Nächstes Jahrzehnt',
            labelToday: 'Heute',
            labelSelected: 'Ausgewähltes Datum',
            labelNoDateSelected: 'Kein Datum gewählt',
            labelCalendar: 'Kalender',
            labelNav: 'Kalendernavigation',
            labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren'
          },*/
          cs: {
            weekdayHeaderFormat: 'narrow',
            labelPrevDecade: 'Předchozí dekáda',
            labelPrevYear: 'Předchozí rok',
            labelPrevMonth: 'Předchozí měsíc',
            labelCurrentMonth: 'Tento měsíc',
            labelNextMonth: 'Příští měsíc',
            labelNextYear: 'Příští rok',
            labelNextDecade: 'Příští dekáda',
            labelToday: 'Dnes',
            labelSelected: 'Vybrané datum',
            labelNoDateSelected: 'Není vybráno žádné datum',
            labelCalendar: 'Kalendář',
            labelNav: 'Navigace kalendáře',
            labelHelp: 'K procházení kalendářem použijte klávesy se šipkami'
          },
          
        } 
        
        //selected: null
      }
    },
    //props: ['deviceList', 'selectedDevice'],
    computed: {
        ...mapGetters(['selectedDeviceId', 'deviceList', 'filterType', 'filterFrom', 'filterTo', 'lang']),
        options() {
            return [
                { value: null, text: this.$t('filter.select_interval'), disabled: true },
                { value: 0, text: this.$t('filter.today') },
                { value: 1, text: this.$t('filter.yesterday') },
                { value: 2, text: this.$t('filter.last_7_days') },
                { value: 3, text: this.$t('filter.last_month')},
                { value: 4, text: this.$t('filter.custom')}
            ] 
        },
        selectedFilter: {
            get() {
                console.log('filterType computed', this.filterType)
                return this.filterType
            }
            //set(value) {
                //console.log('selected filterType value', value)
                //var payload = {filterType: value, filterFrom: null, filterTo: null}
                //this.$emit('filterSelected', payload)
            //}
        }, 
        /* selectedFilter(){
            console.log('filterType computed', this.filterType)
            return this.filterType
        }, */
        selectedFrom: {
            get() {
                console.log('filterFrom computed', this.filterFrom)
                return this.filterFrom
            },
            set(value) {
                console.log('selected filterFrom value', value)
                var datetoStr = this.selectedTo
                if (this.selectedTo != null) {
                    // 2 years check
                    var datefrom = new Date(value)
                    var dateto = new Date(datetoStr)
                    var diff = differenceInDays(dateto, datefrom)
                    if (diff >= 730)
                    {
                        //dateto = addYears(datefrom, 2)
                        //dateto = addDays(dateto, -1)
                        dateto = addDays(datefrom, 729)
                        datetoStr = formatISO(dateto, { representation: 'date' })
                    }

                }
                var payload = {filterType: this.selectedFilter, filterFrom: value, filterTo: datetoStr} //formatISO(value, { representation: 'date' })
                this.$emit('filterSelected', payload)
            }
        },
        selectedTo: {
            get() {
                console.log('filterTo computed', this.filterTo)
                return this.filterTo
            },
            set(value) {
                console.log('selected filterTo value', value)
                var datefromStr = this.selectedFrom
                if (this.selectedFrom != null) {
                    // 2 years check
                    var datefrom = new Date(datefromStr)
                    var dateto = new Date(value)
                    var diff = differenceInDays(dateto, datefrom)
                    if (diff >= 730)
                    {
                        //dateto = addYears(datefrom, 2)
                        //dateto = addDays(dateto, -1)
                        datefrom = addDays(dateto, -729)
                        if (datefrom < dateMinLimit) datefrom = dateMinLimit
                        datefromStr = formatISO(datefrom, { representation: 'date' })
                    }

                }
                var payload = {filterType: this.selectedFilter, filterFrom: datefromStr, filterTo: value} //formatISO(value, { representation: 'date' })
                this.$emit('filterSelected', payload)
            }
        },
        disabled() {
            return this.selectedFilter != 4
        },
        localeCode() {
            return consts.locales[this.lang].code
        },
        fromMin(){
            //var minDate = new Date('2000-01-01')
            /*if (this.filterTo) {
                const toDate = new Date(this.filterTo)
                //const maxDate = new Date(fromDate)
                //minDate = addDays(toDate, -729)
                minDate = addYears(toDate, -2)
                minDate = addDays(minDate, 1)
            }*/
            return dateMinLimit //minDate
        },
        fromMax(){
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            var maxDate = new Date(today)
            if (this.filterTo) {
                maxDate = new Date(this.filterTo)
            }
            console.log('fromMax', today, maxDate)
            return today < maxDate ? today: maxDate
        },
        toMin(){
            var minDate = dateMinLimit
            if (this.filterFrom) {
                minDate = new Date(this.filterFrom)
            }
            return minDate
        },
        toMax(){
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
           /* var maxDate = new Date(today)
            console.log('toMax', today, maxDate)
            if (this.filterFrom) {
                const fromDate = new Date(this.filterFrom)
                maxDate = addYears(fromDate, 2)
                maxDate = addDays(maxDate, -1)
            }
            console.log('toMax', today, maxDate)
            return today < maxDate ? today: maxDate*/
            return today
        },
        disabledRight(){
            var state = false
            if ((this.filterType >= 0) && (this.filterType < 4)) state = true
            if (this.filterType == 1) state = false // yesterday -> today 
            if (this.filterType == 4) {
                if (this.filterFrom == null || this.filterTo == null) {
                    state = true
                } else {
                    const now = new Date()
                    const today = startOfDay(now)//new Date(now.getFullYear(), now.getMonth(), now.getDate())
                    var dateto = new Date(this.filterTo)
                    dateto = startOfDay(dateto)
                    var datefrom = new Date(this.filterFrom)
                    datefrom = startOfDay(datefrom)
                    let diff = differenceInDays(dateto, datefrom)
                    diff += 1 // add one full day, because it calculates diff from 00:00 to 00:00, so one day is missing
                    if ((diff > 27) && (diff < 32)) {
                        // month
                        dateto = addMonths(dateto, 1)
                        datefrom = startOfMonth(dateto)
                        dateto = lastDayOfMonth(dateto)
                    } else {
                        datefrom = addDays(datefrom, diff)
                        dateto = addDays(dateto, diff)
                    }
                    if (datefrom > today) state = true
                }
            }
            return state
            
        },
        disabledLeft(){
            var state = false
            if (this.filterType == 4) {
                if (this.filterFrom == null || this.filterTo == null) {
                    state = true
                } else {
                    
                    const leftLimit = dateMinLimit
                    var dateto = new Date(this.filterTo)
                    var datefrom = new Date(this.filterFrom)
                    let diff = differenceInDays(dateto, datefrom)
                    diff += 1 // add one full day, because it calculates diff from 00:00 to 00:00, so one day is missing
                    if ((diff > 27) && (diff < 32)){
                        // month
                        datefrom = subMonths(datefrom, 1)
                        datefrom = startOfMonth(datefrom)
                        dateto = lastDayOfMonth(datefrom)
                    } else {
                        datefrom = addDays(datefrom, -diff)
                        dateto = addDays(dateto, -diff)
                    }
                    if (datefrom < leftLimit) state = true
                }
            }
            return state
        },
        
    },
    methods: {
        onChange(event) {
            //console.log(event)
            console.log('selected filter event', event)
            //this.$emit('deviceSelected',event)
        },
        onInput(input) {
            //console.log(event)
            console.log('selected filter input event', input)
            //console.log('selected filterType value', value)
            this.selectedFilter = input
            var payload = {filterType: input, filterFrom: null, filterTo: null}
            this.$emit('filterSelected', payload)
            //this.$emit('deviceSelected',event)
        },
        leftBtn(){
            console.log('filter type', this.filterType)
            var dateto = new Date()
            //dateto.setHours(0,0,0,0)
            var datefrom = new Date()
            //datefrom.setHours(0,0,0,0)
            if (this.filterType == 0) { //today -> yesterday
                //this.selectedFilter = 1
                let payload = {filterType: 1}
                this.$emit('filterSelected', payload)
                console.log('left clicked', datefrom, dateto)
                return
            } else if (this.filterType == 1) { // yesterday -> custom
                //dateto.setDate(dateto.getDate() - 2)
                dateto = addDays(dateto, -2)
                datefrom = dateto
                
                console.log('left clicked yesterday to cust ', datefrom, dateto)
            } else if (this.filterType == 2) { // week -> custom
                dateto = addDays(dateto, -8)
                datefrom = addDays(datefrom, -14)
                
                console.log('left clicked week to cust ', datefrom, dateto)
                //dateto.setDate(dateto.getDate() - 8)
                //datefrom.setDate(datefrom.getDate() - 14)
            } else if (this.filterType == 3) { // month -> custom 
                dateto = subMonths(dateto, 1) // posun o mesic
                datefrom = startOfMonth(dateto) // zacatek mesice
                dateto = lastDayOfMonth(dateto) // konec mesice
                
                console.log('left clicked month to cust ', datefrom, dateto)
            } else if (this.filterType == 4) { // custom
                if (this.filterFrom == null || this.filterTo == null) return
                dateto = new Date(this.filterTo)
                datefrom = new Date(this.filterFrom)
                let diff = differenceInDays(dateto, datefrom)
                diff += 1 // add one full day, because it calculates diff from 00:00 to 00:00, so one day is missing
                if ((diff > 27) && (diff < 32)) {
                    // month
                    datefrom = subMonths(datefrom, 1)
                    datefrom = startOfMonth(datefrom)
                    dateto = lastDayOfMonth(datefrom)
                } else {
                    datefrom = addDays(datefrom, -diff)
                    dateto = addDays(dateto, -diff)
                }
                console.log('left clicked custom ', datefrom, dateto)
            }
            let payload = {filterType: 4, filterFrom: formatISO(datefrom, { representation: 'date' }), filterTo: formatISO(dateto, { representation: 'date' })}
            this.$emit('filterSelected', payload)
            console.log('left clicked', datefrom, dateto)

        },
        rightBtn(){
            console.log('filter type', this.filterType)
            var dateto = new Date()
            var datefrom = new Date()
            if (this.filterType == 1) { //yesterday -> today
                //this.selectedFilter = 0
                let payload = {filterType: 0}
                this.$emit('filterSelected', payload)
                console.log('left clicked', datefrom, dateto)
                return
            } else if (this.filterType == 0) { // today -> custom
                datefrom.setDate(dateto.getDate() + 1)
                dateto.setDate(dateto.getDate() + 1)
                console.log('righty clicked', dateto)
                datefrom = dateto
            } else if (this.filterType == 2) { // week -> custom 
                dateto.setDate(dateto.getDate())
                datefrom.setDate(datefrom.getDate() + 7)
            } else if (this.filterType == 3) { // month -> custom
                dateto = addMonths(dateto, 1) // posun o mesic
                datefrom = startOfMonth(dateto) // zacatek mesice
                dateto = lastDayOfMonth(dateto) // konec mesice
            } else if (this.filterType == 4) { // custom
                if (this.filterFrom == null || this.filterTo == null) return
                dateto = new Date(this.filterTo)
                datefrom = new Date(this.filterFrom)
                let diff = differenceInDays(dateto, datefrom)
                diff += 1 // add one full day, because it calculates diff from 00:00 to 00:00, so one day is missing
                if (diff == 1) {
                    if (isYesterday(datefrom)){ //custom -> today
                        //this.selectedFilter = 0
                        let payload = {filterType: 0}
                        this.$emit('filterSelected', payload)
                        console.log('left clicked', datefrom, dateto)
                        return
                    }
                    if (isYesterday(addDays(datefrom,1))) { //custom -> yesterday
                        //this.selectedFilter = 1
                        let payload = {filterType: 1}
                        this.$emit('filterSelected', payload)
                        console.log('left clicked', datefrom, dateto)
                        return
                    }


                }
                if ((diff > 27) && (diff < 32)) {
                    // month
                    dateto = addMonths(dateto, 1)
                    datefrom = startOfMonth(dateto)
                    dateto = lastDayOfMonth(dateto)
                } else {
                    datefrom.setDate(datefrom.getDate() + diff)
                    dateto.setDate(dateto.getDate() + diff)
                }
            }
            let payload = {filterType: 4, filterFrom: formatISO(datefrom, { representation: 'date' }), filterTo: formatISO(dateto, { representation: 'date' })}
            this.$emit('filterSelected', payload)
            console.log('right clicked', datefrom, dateto)
        }
    }    
}
</script>
<style scoped>
@media (max-width: 576px){
    #input-group-1, #input-group-3, #input-group-4 {
        width: 100%!important; 
    } 
  }


</style>
